import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addAsset} from 'src/redux/slices/asset';
import {addClientAsset} from 'src/redux/slices/client';
// utils
import {getNickName} from 'src/utils/formatNickname';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import {UploadMultiFile} from 'src/components/upload';
import NumberFormat from 'src/components/NumberFormat';
import FileEditor from 'src/components/FileEditor';
import {FormikErrorNotification, TextFieldWithHover} from 'src/components/_dashboard/common';
// constants
import {EQ_BREED_TYPE, EQ_CLASS, EQ_USAGE, ASSET_STATUS} from 'src/constants/dropdown';
import {fDateZeroTime} from 'src/utils/formatTime';
// ----------------------------------------------------------------------

const assetDocs = ['trainingDocs', 'imageFiles'];

AircraftDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  inLoading: PropTypes.bool,
  policies: PropTypes.array.isRequired
};

export default function AircraftDialog({handleClose, clientId, open, inLoading = false, policies = []}) {
  const {user} = useAuth();
  const isAdvisor = user && user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [files, setFiles] = useState(assetDocs.reduce((res, key) => ({...res, [key]: []}), {}));
  const AddSchema = Yup.object().shape({
    imageFiles: Yup.array(),
    nickName: Yup.string().required('Please provide name'),
    purDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    soldDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    foalingDate: Yup.date().nullable().min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      regNumber: '', // Registration Number
      nickName: '',
      breed: '',
      class: '',
      usage: '', // Primary Usage
      sex: 'male',
      sire: '', // Sire
      dam: '', // Dam
      purDate: null,
      purFrom: '', // Purchased From
      soldDate: null,
      foalingDate: null, // Foaling Date
      basisCost: '', // Cost Basis
      marketValue: '',
      trainCost: '', // Training Cost
      medInsurance: false, // Medical Insurance
      mortInsurance: false, // Mortality Insurance
      assetStatus: 'active',
      insured: false,
      insuredValue: '',
      rPolicies: [], // Related Policies
      note: '',
      trainingDocs: [], // Training Docs
      imageFiles: []
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, nickName: values.nickName || getNickName(values, 'equine'), type: 'equine'};
        const res = !!clientId
          ? await dispatch(addClientAsset(payload, clientId, !isAdvisor))
          : await dispatch(addAsset(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('New Asset has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/asset/equine/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/asset/equine/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Asset creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, resetForm, getFieldProps, setFieldValue, handleSubmit, isSubmitting, touched, errors} = formik;

  const handleDropMultiFile = useCallback((acceptedFiles, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    }));
  }, []);

  const handleRemoveAll = (key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: []
    }));
  };

  const handleRemove = (file, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: files[key].filter((_file) => _file !== file)
    }));
  };

  const handleUpload = useCallback(
    (uploadedFiles, key) => {
      setFieldValue(key, [...values[key], ...uploadedFiles]);
      setFiles((allFiles) => ({
        ...allFiles,
        [key]: []
      }));
    },
    [setFieldValue, values]
  );

  const handleDeleteFile = (id, key) => {
    setFieldValue(
      key,
      values[key].filter((doc) => doc.id !== id)
    );
  };

  const handleCancelClick = () => {
    resetForm();
    handleClose();
  };

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Asset (Equine)</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextFieldWithHover
                  {...getFieldProps('nickName')}
                  fullWidth
                  type="string"
                  label="Asset Name"
                  info="Input the asset name"
                  error={Boolean(touched.nickName && errors.nickName)}
                  helperText={touched.nickName && errors.nickName}
                />
                <TextField {...getFieldProps('regNumber')} fullWidth type="text" label="Registration Number" />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Breed"
                  {...getFieldProps('breed')}
                  onChange={(e) => setFieldValue('breed', e.target.value)}
                >
                  {EQ_BREED_TYPE.sort((a, b) => (a.label > b.label ? 1 : -1)).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  label="Classification"
                  {...getFieldProps('class')}
                  onChange={(e) => setFieldValue('class', e.target.value)}
                >
                  {EQ_CLASS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Primary Usage"
                  {...getFieldProps('usage')}
                  onChange={(e) => setFieldValue('usage', e.target.value)}
                >
                  {EQ_USAGE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Box sx={{width: '100%'}}>
                  <RadioGroup row {...getFieldProps('sex')}>
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                  </RadioGroup>
                </Box>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField {...getFieldProps('sire')} fullWidth type="text" label="Sire" />
                <TextField {...getFieldProps('dam')} fullWidth type="text" label="Dam" />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <DatePicker
                  fullWidth
                  label="Purchase Date"
                  {...getFieldProps('purDate')}
                  onChange={(date) => setFieldValue('purDate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
                <TextField {...getFieldProps('purFrom')} fullWidth type="text" label="Purchase From" />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <DatePicker
                  fullWidth
                  label="Sold Date"
                  {...getFieldProps('soldDate')}
                  onChange={(date) => setFieldValue('soldDate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
                <DatePicker
                  fullWidth
                  label="Foaling Date"
                  {...getFieldProps('foalingDate')}
                  onChange={(date) => setFieldValue('foalingDate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <NumberFormat
                  fullWidth
                  label="Cost Basis"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('basisCost')}
                  onChange={(e) => setFieldValue('basisCost', parseFloat(e.target.values.value))}
                />
                <NumberFormat
                  fullWidth
                  label="Market Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('marketValue')}
                  onChange={(e) => setFieldValue('marketValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <NumberFormat
                  fullWidth
                  label="Training Cost"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('trainCost')}
                  onChange={(e) => setFieldValue('trainCost', parseFloat(e.target.values.value))}
                />
                <Box sx={{width: '100%'}}>
                  <FormControlLabel
                    control={<Switch {...getFieldProps('medInsurance')} checked={values.medInsurance} />}
                    label="Medical Insurance"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={<Switch {...getFieldProps('mortInsurance')} checked={values.mortInsurance} />}
                    label="Mortality Insurance"
                    labelPlacement="start"
                  />
                </Box>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Asset Status"
                  {...getFieldProps('assetStatus')}
                  onChange={(e) => setFieldValue('assetStatus', e.target.value)}
                  helperText="Please select asset status."
                >
                  {ASSET_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <Box sx={{width: '100%'}}>
                  <FormControlLabel
                    control={<Switch {...getFieldProps('insured')} checked={values.insured} />}
                    label="Insured"
                    labelPlacement="start"
                  />
                </Box>
                {values.insured && (
                  <NumberFormat
                    fullWidth
                    label="Insured Value"
                    prefix="$"
                    placeholder="$0.00"
                    {...getFieldProps('insuredValue')}
                    onChange={(e) => setFieldValue('insuredValue', parseFloat(e.target.values.value))}
                  />
                )}
              </Stack>
              <Autocomplete
                fullWidth
                multiple
                loading={inLoading}
                options={policies}
                ListboxProps={{sx: {maxHeight: '160px'}}}
                disableCloseOnSelect
                getOptionLabel={(option) => `Equine - ${option.policyNumber} - ${option.nickName}`}
                value={policies.filter((item) => values.rPolicies.some((pol) => pol.id === item.id))}
                onChange={(e, value) =>
                  setFieldValue(
                    'rPolicies',
                    value.map((item) => ({id: item.id, type: 'equine'}))
                  )
                }
                renderOption={(props, option, {selected}) => {
                  return (
                    <li {...props} key={option.id}>
                      <Checkbox checked={selected} />
                      Equine - {option.policyNumber} - {option.nickName}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Related Policies" />}
              />
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{pr: 1}}>
                    <Typography variant="body1">Training Docs</Typography>
                    <UploadMultiFile
                      showPreview={false}
                      files={files.trainingDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'trainingDocs')}
                      onRemove={(arg) => handleRemove(arg, 'trainingDocs')}
                      onRemoveAll={() => handleRemoveAll('trainingDocs')}
                      documentType="asset"
                      subType="equine"
                      fileType="trainingDocs"
                      onUpload={(arg) => handleUpload(arg, 'trainingDocs')}
                    />
                    <FileEditor
                      files={values.trainingDocs}
                      deleteFileFunc={(id) => handleDeleteFile(id, 'trainingDocs')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="body1">Asset Images</Typography>
                    <UploadMultiFile
                      showPreview={false}
                      files={files.imageFiles}
                      onDrop={(arg) => handleDropMultiFile(arg, 'imageFiles')}
                      onRemove={(arg) => handleRemove(arg, 'imageFiles')}
                      onRemoveAll={() => handleRemoveAll('imageFiles')}
                      documentType="asset"
                      subType="equine"
                      fileType="imageFiles"
                      onUpload={(arg) => handleUpload(arg, 'imageFiles')}
                    />
                    <FileEditor files={values.imageFiles} deleteFileFunc={(id) => handleDeleteFile(id, 'imageFiles')} />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelClick} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}
