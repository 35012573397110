import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addOrg} from 'src/redux/slices/organization';
import {addClientOrg} from 'src/redux/slices/client';
// utils
import {formatPhoneNumber, getCleanPhoneNumber} from 'src/utils/stringFormat';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import {UploadMultiFile} from 'src/components/upload';
import NumberFormat from 'src/components/NumberFormat';
import FileEditor from 'src/components/FileEditor';
// constants
import {CL_TYPE, ORGANIZATION_STATUS} from 'src/constants/dropdown';
import {AddressFields} from 'src/components/_dashboard/common';
// ----------------------------------------------------------------------
const organizationeDocs = ['byLaws', 'misc'];

ClubDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default function ClubDialog({handleClose, clientId, open}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [files, setFiles] = useState(organizationeDocs.reduce((res, key) => ({...res, [key]: []}), {}));
  const CDSchema = Yup.object().shape({
    name: Yup.string(),
    type: Yup.string(),
    byLaws: Yup.array()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      nickName: '',
      type: '',
      addressId: '',
      primaryPhone: '',
      website: '',
      expense: '',
      due: '',
      status: 'active',
      bondValue: '',
      note: '',
      byLaws: [],
      misc: []
    },
    validationSchema: CDSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: 'club'};
        const res = !!clientId
          ? await dispatch(addClientOrg(payload, clientId, !isAdvisor))
          : await dispatch(addOrg(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('New Org has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/org/club/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/org/club/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Org creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  const handleDropMultiFile = useCallback((acceptedFiles, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    }));
  }, []);

  const handleRemoveAll = (key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: []
    }));
  };

  const handleRemove = (file, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: files[key].filter((_file) => _file !== file)
    }));
  };

  const handleUpload = useCallback(
    (uploadedFiles, key) => {
      setFieldValue(key, [...values[key], ...uploadedFiles]);
      setFiles((allFiles) => ({
        ...allFiles,
        [key]: []
      }));
    },
    [setFieldValue, values]
  );

  const handleDeleteFile = (id, key) => {
    setFieldValue(
      key,
      values[key].filter((doc) => doc.id !== id)
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Club</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('name')}
                  fullWidth
                  type="text"
                  label="Name"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField {...getFieldProps('nickName')} fullWidth type="text" label="Organization Name" />
              </Stack>
              <AddressFields
                isRealEstate={false}
                addId={values.addressId}
                handleChange={(id) => {
                  setFieldValue('addressId', id);
                }}
                inputProps={{
                  error: Boolean(touched.addressId && errors.addressId),
                  helperText: touched.addressId && errors.addressId
                }}
              />
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="Primary Phone"
                  value={formatPhoneNumber(values.primaryPhone)}
                  onChange={(e) => setFieldValue('primaryPhone', getCleanPhoneNumber(e.target.value))}
                  error={Boolean(touched.primaryPhone && errors.primaryPhone)}
                  helperText={touched.primaryPhone && errors.primaryPhone}
                />
                <NumberFormat
                  fullWidth
                  label="Expense"
                  prefix="$"
                  {...getFieldProps('expense')}
                  onChange={(e) => setFieldValue('expense', parseFloat(e.target.values.value))}
                />
                <NumberFormat
                  fullWidth
                  label="Club Dues"
                  prefix="$"
                  {...getFieldProps('due')}
                  onChange={(e) => setFieldValue('due', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Organization Status"
                  {...getFieldProps('status')}
                  onChange={(e) => setFieldValue('status', e.target.value)}
                >
                  {ORGANIZATION_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <NumberFormat
                  fullWidth
                  label="Bond Value"
                  prefix="$"
                  placeholder="$0"
                  {...getFieldProps('bondValue')}
                  onChange={(e) => setFieldValue('bondValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('website')}
                  fullWidth
                  type="text"
                  label="Website"
                  error={Boolean(touched.website && errors.website)}
                  helperText={touched.website && errors.website}
                />
                <TextField
                  select
                  fullWidth
                  label="Type"
                  {...getFieldProps('clubType')}
                  onChange={(e) => setFieldValue('clubType', e.target.value)}
                >
                  {CL_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="body1">By Laws</Typography>
                    <UploadMultiFile
                      showPreview={false}
                      files={files.byLaws}
                      onDrop={(arg) => handleDropMultiFile(arg, 'byLaws')}
                      onRemove={(arg) => handleRemove(arg, 'byLaws')}
                      onRemoveAll={() => handleRemoveAll('byLaws')}
                      documentType="entity"
                      subType="club"
                      fileType="byLaws"
                      onUpload={(arg) => handleUpload(arg, 'byLaws')}
                    />
                    <FileEditor files={values.byLaws} deleteFileFunc={(id) => handleDeleteFile(id, 'byLaws')} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="body1">Miscellaneous</Typography>
                    <UploadMultiFile
                      showPreview={false}
                      files={files.misc}
                      onDrop={(arg) => handleDropMultiFile(arg, 'misc')}
                      onRemove={(arg) => handleRemove(arg, 'misc')}
                      onRemoveAll={() => handleRemoveAll('misc')}
                      documentType="entity"
                      subType="club"
                      fileType="misc"
                      onUpload={(arg) => handleUpload(arg, 'misc')}
                    />
                    <FileEditor files={values.misc} deleteFileFunc={(id) => handleDeleteFile(id, 'misc')} />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}
