import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {capitalCase} from 'change-case';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
import {useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Radio,
  RadioGroup
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import DatePicker from '@mui/lab/DatePicker';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addInsurance} from 'src/redux/slices/insurance';
import {addClientInsurance} from 'src/redux/slices/client';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {fCurrency} from 'src/utils/formatNumber';
import {fDate, fDateZeroTime} from 'src/utils/formatTime';
import {getInsStatusByDate} from 'src/utils/getInsStatusByDate';
// components
import Label from 'src/components/Label';
import {UploadMultiFile} from 'src/components/upload';
import NumberFormat from 'src/components/NumberFormat';
import FileEditor from 'src/components/FileEditor';
import DisplayInsuranceList from 'src/components/canopy/DisplayInsuranceList';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
import {ContactSelect, FormikErrorNotification, TextFieldWithHover} from 'src/components/_dashboard/common';
// constants
import {IMPORT_OPTIONS} from 'src/constants/insurance';
import {INSURANCE_STATUS, IN_CO_TYPE} from 'src/constants/dropdown';
// ----------------------------------------------------------------------
const insuranceDocs = ['policyDocs'];
const SELECT_ALL = {value: 'all', label: 'Select All'}; // NOTE: Options to select all coverage
const DESELECT_ALL = {value: 'clear', label: 'Deselect All'}; // NOTE: Options to deselect all coverage
const SELECT_ALL_RISK = {id: 'all', nickName: 'Select All'}; // NOTE: Options to deselect all risk
const DESELECT_ALL_RISK = {id: 'clear', nickName: 'Deselect All'}; // NOTE: Options to deselect all risk

AddDialog.propTypes = {
  passOptionStep: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  inLoading: PropTypes.bool,
  RISKS: PropTypes.array,
  policies: PropTypes.array,
  CLAIMS: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default function AddDialog({
  passOptionStep,
  clientId,
  tab,
  isLoading = false,
  inLoading = false,
  RISKS = [],
  policies = [],
  CLAIMS = [],
  handleClose
}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [step, setStep] = useState(passOptionStep ? 2 : 1);
  const [option, setOption] = useState(IMPORT_OPTIONS.manual);
  const [files, setFiles] = useState(insuranceDocs.reduce((res, key) => ({...res, [key]: []}), {}));

  const AddSchema = Yup.object().shape({
    policyNumber: Yup.string().required('Policy Number is required'),
    policyDocs: Yup.array(),
    effDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    expDate: Yup.date().typeError('Expiration Date is required.').min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      policyNumber: '',
      nickName: '',
      carrier: '',
      broker: '',
      primaryContact: [],
      issuingCompany: '',
      premium: '',
      namedInsured: [],
      additionalInsured: [],
      effDate: null,
      expDate: null,
      coverage: [],
      status: 'active',
      rPolicies: [], // Related Policy IDS
      rClaims: [], // Related Claim IDS
      policyDocs: [],
      note: ''
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: tab};
        const res =
          !!clientId || memberSelected.length === 1
            ? await dispatch(addClientInsurance(payload, clientId, !isAdvisor))
            : await dispatch(addInsurance(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('Add Insurance success', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/insurance/collection/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/insurance/collection/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Insurance creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, setValues, handleSubmit, isSubmitting} =
    formik;

  const handleDropMultiFile = useCallback((acceptedFiles, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    }));
  }, []);

  const handleRemoveAll = (key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: []
    }));
  };

  const handleRemove = (file, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: files[key].filter((_file) => _file !== file)
    }));
  };

  const handleUpload = useCallback(
    (uploadedFiles, key) => {
      setFieldValue(key, [...values[key], ...uploadedFiles]);
      setFiles((allFiles) => ({
        ...allFiles,
        [key]: []
      }));
    },
    [setFieldValue, values]
  );

  const setCovValue = (value) => {
    const updateValues = value.find((item) => item.value === 'all') // NOTE: If select all
      ? IN_CO_TYPE
      : value.find((item) => item.value === 'clear') // NOTE: If deselect all
      ? []
      : value;

    setFieldValue(
      'coverage',
      updateValues.map((item) => {
        if (values.coverage.some((ce) => ce.key === item.value)) {
          return values.coverage.find((ce) => ce.key === item.value);
        } else {
          return {key: item.value, label: item.label, blankedLimit: '', scheduledLimit: '', scheduledItems: []};
        }
      })
    );
  };

  const handleBlankedLimitChange = (key, value) => {
    let newCoverageValue = cloneDeep(values.coverage);
    const target = newCoverageValue.find((item) => item.key === key);
    target.blankedLimit = value;

    setFieldValue('coverage', newCoverageValue);
  };

  const handleCoverageItemChange = (key, value) => {
    const allOptions = RISKS.filter((item) => item.class === key);
    // NOTE: Check if select all option is selected or not
    const updatedValues = value.find((item) => item.id === 'all')
      ? allOptions
      : value.find((item) => item.id === 'clear')
      ? []
      : value;

    let newCoverageValue = cloneDeep(values.coverage);
    const target = newCoverageValue.find((item) => item.key === key);
    target.scheduledItems = updatedValues.map(
      (v) => target.scheduledItems.find((item) => item.id === v.id) || {id: v.id, name: v.nickName, value: null}
    );
    target.scheduledLimit = target.scheduledItems.reduce((sum, item) => sum + (item.value || 0), 0);

    setFieldValue('coverage', newCoverageValue);
  };

  const handleCoverageItemPriceChange = (key, itemId, value) => {
    let newCoverageValue = cloneDeep(values.coverage);
    const target = newCoverageValue.find((item) => item.key === key);
    const targetItem = target.scheduledItems.find((item) => item.id === itemId);
    targetItem.value = value;
    target.scheduledLimit = target.scheduledItems.reduce((sum, item) => sum + (item.value || 0), 0);

    setFieldValue('coverage', newCoverageValue);
  };

  const handleCoverageItemSwitchChange = (key, itemId, subKey, value) => {
    let newCoverageValue = cloneDeep(values.coverage);
    const target = newCoverageValue.find((item) => item.key === key);
    const targetItem = target.scheduledItems.find((item) => item.id === itemId);
    targetItem[subKey] = value;

    setFieldValue('coverage', newCoverageValue);
  };

  const handleDeleteFile = (id, key) => {
    setFieldValue(
      key,
      values[key].filter((doc) => doc.id !== id)
    );
  };

  const handleInsStatusByEffecDate = (date) => {
    setValues({
      ...values,
      effDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'effDate')
    });
  };

  const handleInsStatusByExpDate = (date) => {
    setValues({
      ...values,
      expDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'expDate')
    });
  };

  const isManual = option === IMPORT_OPTIONS.manual;
  const isAllCoverageSelected = values.coverage.length === IN_CO_TYPE.length;
  const isAllRiskSelected = (coverageKey) => {
    const targetCoverage = values.coverage.find((item) => item.key === coverageKey);
    const targetRisks = RISKS.filter((item) => item.class === coverageKey);

    return targetCoverage.scheduledItems.length === targetRisks.length;
  };

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={isManual || step === 1} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>{`Add New Insurance${isManual ? ' (Collection)' : ''}`}</DialogTitle>
          <Divider />
          <DialogContent>
            {step === 1 ? (
              <RadioGroup name="radio-buttons-group" value={option} onChange={(e) => setOption(e.target.value)}>
                <FormControlLabel value={IMPORT_OPTIONS.manual} control={<Radio />} label="Add Manually" />
                <FormControlLabel value={IMPORT_OPTIONS.carrier} control={<Radio />} label="Import From Carrier" />
              </RadioGroup>
            ) : (
              isManual && (
                <Stack spacing={2}>
                  {/* Top section - identify policy, select risk */}
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: 'center'}}>
                    <TextField
                      {...getFieldProps('policyNumber')}
                      fullWidth
                      type="text"
                      label="Policy Number"
                      error={Boolean(touched.policyNumber && errors.policyNumber)}
                      helperText={touched.policyNumber && errors.policyNumber}
                    />
                    <TextFieldWithHover
                      {...getFieldProps('nickName')}
                      fullWidth
                      type="string"
                      label="Policy Name"
                      info="Input the policy name"
                    />
                    <Box>
                      <Tooltip title="Add new asset">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            navigate('/dashboard/asset', {state: {view: 'collection', showModal: true, goBack: true}});
                          }}
                        >
                          <AddIcon width={24} height={24} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: 'center'}}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="primaryContact"
                      label="Primary Contact"
                      values={values.primaryContact}
                      setFieldValue={setFieldValue}
                      isPrimary={true}
                    />
                    <TextField
                      {...getFieldProps('carrier')}
                      fullWidth
                      type="text"
                      label="Insurance Carrier"
                      error={Boolean(touched.carrier && errors.carrier)}
                      helperText={touched.carrier && errors.carrier}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      {...getFieldProps('broker')}
                      fullWidth
                      type="text"
                      label="Brokerage"
                      error={Boolean(touched.broker && errors.broker)}
                      helperText={touched.broker && errors.broker}
                    />
                    <TextField
                      {...getFieldProps('issuingCompany')}
                      fullWidth
                      type="text"
                      label="Issuing Company"
                      error={Boolean(touched.issuingCompany && errors.issuingCompany)}
                      helperText={touched.issuingCompany && errors.issuingCompany}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                    <DatePicker
                      fullWidth
                      label="Effective Date"
                      {...getFieldProps('effDate')}
                      onChange={handleInsStatusByEffecDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                    <DatePicker
                      fullWidth
                      label="Expiration Date*"
                      {...getFieldProps('expDate')}
                      onChange={handleInsStatusByExpDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={Boolean(touched.expDate && errors.expDate)}
                          helperText={touched.expDate && errors.expDate}
                        />
                      )}
                    />
                    <StatusLabel status={values.status} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="namedInsured"
                      label="Named Insured"
                      values={values.namedInsured}
                      setFieldValue={setFieldValue}
                    />
                    <ContactSelect
                      clientId={clientId}
                      keyValue="additionalInsured"
                      label="Additional Insured"
                      values={values.additionalInsured}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>

                  {/* Middle section - coverage limits */}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Premium"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('premium')}
                      onChange={(e) => setFieldValue('premium', parseFloat(e.target.values.value))}
                    />
                  </Stack>
                  <Stack>
                    <Autocomplete
                      fullWidth
                      multiple
                      limitTags={3}
                      options={[isAllCoverageSelected ? DESELECT_ALL : SELECT_ALL, ...IN_CO_TYPE]}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      value={IN_CO_TYPE.filter((item) => values.coverage.some((cv) => cv.key === item.value))}
                      onChange={(e, value) => setCovValue(value)}
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Select Coverage" />}
                    />
                  </Stack>
                  {values.coverage.map((cv) => (
                    <Stack sx={{mb: 1}} spacing={1} key={cv.key}>
                      <Box>
                        <Label variant={'outlined'} color={'primary'}>
                          {cv.label}
                        </Label>
                      </Box>
                      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                        <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <Typography>{`Scheduled Items: $${cv.scheduledLimit || 0}`}</Typography>
                        </Box>
                        <NumberFormat
                          fullWidth
                          label="Blanket Limit"
                          prefix="$"
                          placeholder="$0.00"
                          value={cv.blankedLimit}
                          onChange={(e) => handleBlankedLimitChange(cv.key, parseFloat(e.target.values.value))}
                        />
                      </Stack>
                      <Stack spacing={1}>
                        <Stack>
                          <Autocomplete
                            loading={isLoading}
                            fullWidth
                            multiple
                            limitTags={3}
                            options={[
                              isAllRiskSelected(cv.key) ? DESELECT_ALL_RISK : SELECT_ALL_RISK,
                              ...RISKS.filter((item) => item.class === cv.key)
                            ]}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.nickName || option.id}
                            value={RISKS.filter((item) => cv.scheduledItems.find((sItem) => sItem.id === item.id))}
                            onChange={(e, value) => handleCoverageItemChange(cv.key, value)}
                            renderOption={(props, option, {selected}) => {
                              return (
                                <li {...props} key={option.id}>
                                  <Checkbox checked={selected} />
                                  {`${option.nickName || option.id}${
                                    option.purchasePrice ? ` (${fCurrency(option.purchasePrice)})` : ''
                                  }`}
                                </li>
                              );
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Risk" />}
                          />
                        </Stack>
                        {!!cv.scheduledItems.length && (
                          <Stack
                            sx={{
                              maxHeight: '800px',
                              overflow: 'auto',
                              p: 1
                            }}
                            spacing={1}
                          >
                            {cv.scheduledItems.map((item) => (
                              <Stack direction={{xs: 'column', md: 'row'}} spacing={2} key={item.id}>
                                <TextField
                                  disabled
                                  defaultValue={RISKS.find((risk) => risk.id === item.id).nickName}
                                  type="text"
                                  label="Name"
                                />
                                <NumberFormat
                                  required
                                  label="Value"
                                  prefix="$"
                                  placeholder="$0.00"
                                  value={item.value}
                                  onChange={(e) =>
                                    handleCoverageItemPriceChange(cv.key, item.id, parseFloat(e.target.values.value))
                                  }
                                />
                                <FormGroup row>
                                  <FormControlLabel
                                    value="top"
                                    control={
                                      <Switch
                                        color="primary"
                                        size="small"
                                        checked={item.homeSafe}
                                        onChange={(e) =>
                                          handleCoverageItemSwitchChange(cv.key, item.id, 'homeSafe', e.target.checked)
                                        }
                                      />
                                    }
                                    label={<Typography sx={{fontSize: 10}}>Home Safe / Alarm</Typography>}
                                    labelPlacement="top"
                                  />
                                  <FormControlLabel
                                    value="top"
                                    control={
                                      <Switch
                                        color="primary"
                                        size="small"
                                        checked={item.bankVault}
                                        onChange={(e) =>
                                          handleCoverageItemSwitchChange(cv.key, item.id, 'bankVault', e.target.checked)
                                        }
                                      />
                                    }
                                    label={<Typography sx={{fontSize: 10}}>Bank Vault</Typography>}
                                    labelPlacement="top"
                                  />
                                  <FormControlLabel
                                    value="top"
                                    control={
                                      <Switch
                                        color="primary"
                                        size="small"
                                        checked={item.onLoan}
                                        onChange={(e) =>
                                          handleCoverageItemSwitchChange(cv.key, item.id, 'onLoan', e.target.checked)
                                        }
                                      />
                                    }
                                    label={<Typography sx={{fontSize: 10}}>On Loan</Typography>}
                                    labelPlacement="top"
                                  />
                                </FormGroup>
                              </Stack>
                            ))}
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  ))}
                  {/* Bottom section */}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      {...getFieldProps('status')}
                      onChange={(e) => setFieldValue('status', e.target.value)}
                    >
                      {INSURANCE_STATUS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack spacing={2}>
                    <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={policies}
                      ListboxProps={{sx: {maxHeight: '160px'}}}
                      disableCloseOnSelect
                      getOptionLabel={(option) => `Collection - ${option.policyNumber} - ${option.nickName}`}
                      value={policies.filter((item) => values.rPolicies.some((pol) => pol.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rPolicies',
                          value.map((item) => ({id: item.id, type: 'collection'}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            Collection - {option.policyNumber} - {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Policies" />}
                    />
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={CLAIMS}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        `${fDate(option.dateOfLoss)} - ${option.policyNumber} - ${capitalCase(option.policyType)} - ${
                          option.nickName
                        }`
                      }
                      value={CLAIMS.filter((item) => values.rClaims.includes(item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rClaims',
                          value.map((item) => item.id)
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {fDate(option.dateOfLoss)} - {option.policyNumber} - {capitalCase(option.policyType)} -{' '}
                            {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Claims" />}
                    />
                  </Stack>
                  <Box>
                    <Typography variant="h6">Policy Documents</Typography>
                    <UploadMultiFile
                      showPreview={false}
                      files={files.policyDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'policyDocs')}
                      onRemove={(arg) => handleRemove(arg, 'policyDocs')}
                      onRemoveAll={() => handleRemoveAll('policyDocs')}
                      documentType="policy"
                      subType="collection"
                      fileType="policyDocs"
                      onUpload={(arg) => handleUpload(arg, 'policyDocs')}
                    />
                    <FileEditor files={values.policyDocs} deleteFileFunc={(id) => handleDeleteFile(id, 'policyDocs')} />
                  </Box>
                </Stack>
              )
            )}
          </DialogContent>
          {step === 1 ? (
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  handleClose();
                  setStep(1);
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={() => setStep(2)}>
                Next
              </LoadingButton>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => setStep(1)} color="inherit">
                Back
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                Submit
              </LoadingButton>
            </DialogActions>
          )}
        </Dialog>
      </Form>
      {step === 2 && option === IMPORT_OPTIONS.carrier && (
        <DisplayInsuranceList handleReset={() => setStep(1)} handleClose={handleClose} />
      )}
    </FormikProvider>
  );
}
