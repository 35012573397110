import {Icon} from '@iconify/react';
import {flatten} from 'lodash';
import horseHuman from '@iconify/icons-mdi/horse-human';
// mui
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeckIcon from '@mui/icons-material/Deck';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import AdjustIcon from '@mui/icons-material/Adjust';
import AppsIcon from '@mui/icons-material/Apps';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import FlightIcon from '@mui/icons-material/Flight';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import WarningIcon from '@mui/icons-material/Warning';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import SettingsIcon from '@mui/icons-material/Settings';
// components
import {
  General as GAsset,
  RealEstate as RAsset,
  Collection as CAsset,
  Automobile as AAsset,
  Watercraft as WAsset,
  Aircraft as AiAsset,
  Equine as EAsset,
  Divested as DAsset
} from 'src/components/_dashboard/assets';

import {
  General as GeneralInsurance,
  RealEstate as RInsurance,
  Collection as CInsurance,
  Automobile as AInsurance,
  Watercraft as WInsurance,
  Aircraft as AiInsurance,
  Umbrella as UmInsurance,
  Equine as EInsurance,
  Misc as MiInsurance,
  Claims as ClInsurance,
  Dormant as DoInsurance
} from 'src/components/_dashboard/insurance';

import {
  Boards as BOrg,
  Entities as EOrg,
  Trusts as TOrg,
  Clubs as COrg,
  Prior as POrg
} from 'src/components/_dashboard/organization';

export const CONTACT_TYPE = [
  {
    label: 'All',
    key: 'all',
    icon: <AppsIcon />
  },
  {
    label: 'Individual/Family',
    key: 'individual',
    icon: <EmojiEmotionsIcon />
  },
  {
    label: 'Staff',
    key: 'advisor',
    advisorKey: 'staff',
    icon: <HeadsetMicIcon />
  },
  {
    label: 'Insurance',
    key: 'advisor',
    advisorKey: 'insurance',
    icon: <SecurityIcon />
  },
  {
    label: 'Accounting',
    key: 'advisor',
    advisorKey: 'accounting',
    icon: <SupervisorAccountIcon />
  },
  {
    label: 'Financial',
    key: 'advisor',
    advisorKey: 'financial',
    icon: <MonetizationOnIcon />
  },
  {
    label: 'Legal',
    key: 'advisor',
    advisorKey: 'legal',
    icon: <GavelIcon />
  },
  {
    label: 'Real Estate',
    key: 'advisor',
    advisorKey: 'realEstate',
    icon: <HomeWorkIcon />
  },
  {
    label: 'Institutions',
    key: 'institutions',
    advisorKey: 'institutions',
    icon: <RoomPreferencesIcon />
  },
  {
    label: 'Other',
    key: 'advisor',
    advisorKey: 'other',
    icon: <AdjustIcon />
  }
];

export const assetTabIcons = [
  {
    label: 'General',
    value: 'general',
    icon: <SettingsIcon />
  },
  {
    label: 'Real Estate',
    value: 'realEstate',
    icon: <ApartmentIcon />
  },
  {
    label: 'Collections',
    value: 'collection',
    icon: <LibraryBooksIcon />
  },
  {
    label: 'Automobiles',
    value: 'automobile',
    icon: <DriveEtaIcon />
  },
  {
    label: 'Watercraft',
    value: 'watercraft',
    icon: <DirectionsBoatIcon />
  },
  {
    label: 'Aircraft',
    value: 'aircraft',
    icon: <FlightIcon />
  },
  {
    label: 'Equine',
    value: 'equine',
    icon: <Icon icon={horseHuman} width={24} height={24} />
  },
  {
    label: 'Divested',
    value: 'divested',
    icon: <CleaningServicesIcon />
  }
];

export const getAssetIcon = (type) => {
  const matchedTab = assetTabIcons.find((asset) => asset.value === type);
  if (matchedTab) {
    return matchedTab.icon;
  }
};

export const assetComponents = (mode, view, assets = {}, chartData = {}) => [
  <GAsset mode={mode} view={view} data={assets.general} chartData={chartData.general} />,
  <RAsset mode={mode} view={view} data={assets.realEstate} chartData={chartData.realEstate} />,
  <CAsset mode={mode} view={view} data={assets.collection} chartData={chartData.collection} />,
  <AAsset mode={mode} view={view} data={assets.automobile} chartData={chartData.automobile} />,
  <WAsset mode={mode} view={view} data={assets.watercraft} chartData={chartData.watercraft} />,
  <AiAsset mode={mode} view={view} data={assets.aircraft} chartData={chartData.aircraft} />,
  <EAsset mode={mode} view={view} data={assets.equine} chartData={chartData.equine} />,
  <DAsset
    mode={mode}
    view={view}
    data={flatten(Object.values(assets)).filter((item) => item.assetStatus === 'divested')}
    chartData={chartData.divested}
  />
];

export const ASSET_TABS_FUNC = (mode, view, assets = {}, chartData = {}) =>
  assetTabIcons.map((assetItem, i) => ({...assetItem, component: assetComponents(mode, view, assets, chartData)[i]}));

export const INSURANCE_TABS_FUNC = (mode, view, insurance = {}, chartData) => [
  {
    label: 'General',
    value: 'general',
    component: <GeneralInsurance mode={mode} view={view} data={insurance.general} chartData={chartData.general} />,
    icon: <SettingsIcon />
  },
  {
    label: 'Real Estate',
    value: 'realEstate',
    component: <RInsurance mode={mode} view={view} data={insurance.realEstate} chartData={chartData.realEstate} />,
    icon: <ApartmentIcon />
  },
  {
    label: 'Collections',
    value: 'collection',
    component: <CInsurance mode={mode} view={view} data={insurance.collection} chartData={chartData.collection} />,
    icon: <LibraryBooksIcon />
  },
  {
    label: 'Automobiles',
    value: 'automobile',
    component: <AInsurance mode={mode} view={view} data={insurance.automobile} chartData={chartData.automobile} />,
    icon: <DriveEtaIcon />
  },
  {
    label: 'Watercraft',
    value: 'watercraft',
    component: <WInsurance mode={mode} view={view} data={insurance.watercraft} chartData={chartData.watercraft} />,
    icon: <DirectionsBoatIcon />
  },
  {
    label: 'Aircraft',
    value: 'aircraft',
    component: <AiInsurance mode={mode} view={view} data={insurance.aircraft} chartData={chartData.aircraft} />,
    icon: <FlightIcon />
  },
  {
    label: 'Equine',
    value: 'equine',
    component: <EInsurance mode={mode} view={view} data={insurance.equine} chartData={chartData.equine} />,
    icon: <Icon icon={horseHuman} width={24} height={24} />
  },
  {
    label: 'Umbrella',
    value: 'umbrella',
    component: <UmInsurance mode={mode} view={view} data={insurance.umbrella} chartData={chartData.umbrella} />,
    icon: <BeachAccessIcon />
  },
  {
    label: 'Misc',
    value: 'misc',
    component: <MiInsurance mode={mode} view={view} data={insurance.misc} />,
    icon: <MiscellaneousServicesIcon />
  },
  {
    label: 'Claims',
    value: 'claim',
    component: <ClInsurance mode={mode} view={view} data={insurance.claim} chartData={chartData.claim} />,
    icon: <WarningIcon />
  },
  {
    label: 'Dormant',
    value: 'dormant',
    component: <DoInsurance mode={mode} view={view} data={insurance} />,
    icon: <TextSnippetIcon />
  }
];

export const ORG_TABS_FUNC = (mode, view, orgs = {}, chartData = {}) => [
  {
    label: 'Boards',
    value: 'board',
    component: (
      <BOrg
        mode={mode}
        view={view}
        data={orgs.board?.filter((item) => item.status === 'active')}
        chartData={chartData.board}
      />
    ),
    icon: <GroupIcon />
  },
  {
    label: 'Entities',
    value: 'entity',
    component: (
      <EOrg
        mode={mode}
        view={view}
        data={orgs.entity?.filter((item) => item.status === 'active')}
        chartData={chartData.entity}
      />
    ),
    icon: <BusinessIcon />
  },
  {
    label: 'Trusts & Estates',
    value: 'trust',
    component: (
      <TOrg
        mode={mode}
        view={view}
        data={orgs.trust?.filter((item) => item.status === 'active')}
        chartData={chartData.trust}
      />
    ),
    icon: <AccountBalanceIcon />
  },
  {
    label: 'Clubs',
    value: 'club',
    component: (
      <COrg
        mode={mode}
        view={view}
        data={orgs.club?.filter((item) => item.status === 'active')}
        chartData={chartData.club}
      />
    ),
    icon: <DeckIcon />
  },
  {
    label: 'Prior Entities & Activity',
    value: 'prior',
    component: <POrg mode={mode} view={view} data={orgs} />,
    icon: <TextSnippetIcon />
  }
];

export const getAssetLabel = (key) => {
  const dummyAssetTabs = ASSET_TABS_FUNC('', '', {}, {});
  const target = dummyAssetTabs.find((item) => item.value === key);

  return target ? target.label : '';
};

export const getOrgLabel = (key) => {
  const dummyOrgTabs = ORG_TABS_FUNC('', '', {});
  const target = dummyOrgTabs.find((item) => item.value === key);

  return target ? target.label : '';
};

export const getInsuranceLabel = (key) => {
  const dummyInsuranceTabs = INSURANCE_TABS_FUNC('', '', {}, [], {});
  const target = dummyInsuranceTabs.find((item) => item.value === key);

  return target ? target.label : '';
};
