import {useEffect, useState} from 'react';
import {isString} from 'lodash';
import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import {useDropzone} from 'react-dropzone';
import {useSnackbar} from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import {motion, AnimatePresence} from 'framer-motion';
import convert from 'heic-convert';
// mui
import {alpha, styled} from '@mui/material/styles';
import {Box, CircularProgress, Stack, Paper, ListItem, Typography} from '@mui/material';
// utils
import axios from 'src/utils/axios';
import {fData} from '../../utils/formatNumber';
//hooks
import useAuth from 'src/hooks/useAuth';
//redux
import {useSelector} from 'react-redux';
// components
import {MIconButton} from '../@material-extend';
import {varFadeInRight} from '../animate';
import {UploadIllustration} from '../../assets';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({theme}) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {opacity: 0.72, cursor: 'pointer'},
  [theme.breakpoints.up('md')]: {textAlign: 'left', flexDirection: 'row'}
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object,
  onUpload: PropTypes.func,
  documentType: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  size: PropTypes.string
};

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  sx,
  onUpload,
  documentType,
  subType,
  fileType,
  itemId = '',
  size,
  ...other
}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {list} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);

  const clientId = (isAdvisor ? list : members).find((item) => item.show)?.id || '';
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const fileVaultUploadApi =
    isAdvisor || memberSelected.length === 1
      ? `/advisor/file-vault/upload?userId=${clientId}&isFamilyGroup=${!isAdvisor}`
      : 'file-vault/upload';

  const hasFile = files.length > 0;
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({
    ...other
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({file, errors}) => {
        const {path, size} = file;
        return (
          <Box key={path} sx={{my: 1}}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  const handleUploadFiles = async () => {
    if (!documentType) {
      enqueueSnackbar('documentType is required', {
        variant: 'error'
      });
      return;
    }

    const heicFiles = files.filter((file) => file.name.split('.').reverse()[0] === 'heic');
    const nonHeicFiles = files.filter((file) => file.name.split('.').reverse()[0] !== 'heic');
    let jpgFiles = [];
    try {
      jpgFiles = await Promise.all(
        heicFiles.map(async (file) => {
          const buffer = await file.arrayBuffer();
          const uintData = new Uint8Array(buffer);
          const convertedUint = await convert({
            buffer: uintData, // the HEIC file buffer
            format: 'JPEG', // output format
            quality: 1 // the jpeg compression quality, between 0 and 1
          });
          const blob = new Blob([convertedUint], {
            type: 'image/jpeg'
          });
          return new File([blob], 'converted.jpeg');
        })
      );
    } catch (err) {
      console.log(err, 'Heic file converting error');
    }

    setLoading(true);
    try {
      const uploadedFiles = await Promise.all(
        [...nonHeicFiles, ...jpgFiles].map(async (file) => {
          const formData = new FormData();
          formData.append('documentTitle', '');
          formData.append('documentType', documentType);
          formData.append('subType', subType);
          formData.append('fileType', fileType);
          if (!!itemId) formData.append('itemId', itemId);
          formData.append('post', file);
          const uploadedFile = await axios.post(fileVaultUploadApi, formData).then((resp) => {
            setLoading(false);
            return resp.data;
          });
          return uploadedFile;
        })
      );
      enqueueSnackbar('File(s) Added Success', {
        variant: 'success'
      });
      if (onUpload) {
        onUpload(uploadedFiles);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.message ?? 'File upload error', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (hasFile) handleUploadFiles();
    // eslint-disable-next-line
  }, [hasFile]);

  return (
    <Box sx={{width: '100%', ...sx}}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && {opacity: 0.72}),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        {loading ? (
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px'}}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <input {...getInputProps()} />
            <UploadIllustration sx={{width: 220}} />
            <Box sx={{p: size === 'small' ? 1 : 3, ml: {md: 2}}}>
              <Stack>
                <Typography gutterBottom variant={size === 'small' ? 'button' : 'h5'}>
                  Drop or Select file
                </Typography>
                {size !== 'small' && (
                  <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    Drop files here or click&nbsp;
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{color: 'primary.main', textDecoration: 'underline'}}
                    >
                      browse
                    </Typography>
                    &nbsp;through your machine
                  </Typography>
                )}
              </Stack>
            </Box>
          </>
        )}
      </DropZoneStyle>
      {fileRejections.length > 0 && <ShowRejectionItems />}
      <AnimatePresence>
        {files.map((file) => {
          const {name, preview} = file;
          const key = isString(file) ? file : name;

          return showPreview ? (
            <ListItem
              key={key}
              component={motion.div}
              {...varFadeInRight}
              sx={{
                p: 0,
                m: 0.5,
                width: 80,
                height: 80,
                borderRadius: 1.5,
                overflow: 'hidden',
                position: 'relative',
                display: 'inline-flex'
              }}
            >
              <Paper
                variant="outlined"
                component="img"
                src={isString(file) ? file : preview}
                sx={{width: '100%', height: '100%', objectFit: 'cover', position: 'absolute'}}
              />
              <Box sx={{top: 6, right: 6, position: 'absolute'}}>
                <MIconButton
                  size="small"
                  onClick={() => onRemove(file)}
                  sx={{
                    p: '2px',
                    color: 'common.white',
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                    }
                  }}
                >
                  <Icon icon={closeFill} />
                </MIconButton>
              </Box>
            </ListItem>
          ) : null;
        })}
      </AnimatePresence>
    </Box>
  );
}
