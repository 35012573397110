import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {capitalCase} from 'change-case';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
import {pick} from 'lodash';
import {useSelector} from 'react-redux';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addInsurance} from 'src/redux/slices/insurance';
import {addClientInsurance} from 'src/redux/slices/client';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {fDate, fDateZeroTime} from 'src/utils/formatTime';
import {getInsStatusByDate} from 'src/utils/getInsStatusByDate';
// components
import {UploadMultiFile} from 'src/components/upload';
import NumberFormat from 'src/components/NumberFormat';
import FileEditor from 'src/components/FileEditor';
import DisplayInsuranceList from 'src/components/canopy/DisplayInsuranceList';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
import {ContactSelect, FormikErrorNotification, TextFieldWithHover} from 'src/components/_dashboard/common';
// constants
import {IMPORT_OPTIONS} from 'src/constants/insurance';
import {IN_UM_TYPE, INSURANCE_STATUS} from 'src/constants/dropdown';
// ----------------------------------------------------------------------
const insuranceDocs = ['policyDocs'];

AddDialog.propTypes = {
  passOptionStep: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  inLoading: PropTypes.bool,
  filteredRisk: PropTypes.array,
  policies: PropTypes.array,
  CLAIMS: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default function AddDialog({
  passOptionStep,
  clientId,
  tab,
  isLoading = false,
  inLoading = false,
  RISKS = [],
  policies = [],
  CLAIMS = [],
  handleClose
}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {list} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const cId = (isAdvisor ? list : members).find((item) => item.show)?.id || '';
  const filteredRisk = memberSelected.length === 1 ? RISKS.filter((risk) => risk.user === cId) : RISKS;

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [step, setStep] = useState(passOptionStep ? 2 : 1);
  const [option, setOption] = useState(IMPORT_OPTIONS.manual);
  const [files, setFiles] = useState(insuranceDocs.reduce((res, key) => ({...res, [key]: []}), {}));

  const AddSchema = Yup.object().shape({
    policyType: Yup.string().required('Policy type is required'),
    policyNumber: Yup.string().required('Policy Number is required'),
    policyDocs: Yup.array(),
    effDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    expDate: Yup.date().typeError('Expiration Date is required.').min(new Date(1900, 0, 1))
  });

  const formik = useFormik({
    initialValues: {
      policyType: '',
      policyNumber: '',
      nickName: '',
      carrier: '',
      broker: '',
      primaryContact: [],
      issuingCompany: '',
      risks: [],
      namedInsured: [],
      additionalInsured: [],
      effDate: null,
      expDate: null,
      premium: '',
      excessLiability: '',
      excessUninsuredMotorist: '',
      excessThirdPartyLiability: '',
      employmentPracticesLiability: false,
      perOccurrence: '',
      annualAggregate: '',
      retention: '',
      status: 'active',
      rPolicies: [], // Related Policy IDS
      rClaims: [], // Related Claim IDS
      policyDocs: [],
      note: ''
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: tab};
        const res =
          !!clientId || memberSelected.length === 1
            ? await dispatch(addClientInsurance(payload, clientId, !isAdvisor))
            : await dispatch(addInsurance(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('Add Insurance success', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/insurance/umbrella/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/insurance/umbrella/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Insurance creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, setValues, handleSubmit, isSubmitting} =
    formik;

  const handleDropMultiFile = useCallback((acceptedFiles, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    }));
  }, []);

  const handleRemoveAll = (key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: []
    }));
  };

  const handleRemove = (file, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: files[key].filter((_file) => _file !== file)
    }));
  };

  const handleUpload = useCallback(
    (uploadedFiles, key) => {
      setFieldValue(key, [...values[key], ...uploadedFiles]);
      setFiles((allFiles) => ({
        ...allFiles,
        [key]: []
      }));
    },
    [setFieldValue, values]
  );

  const handleDeleteFile = (id, key) => {
    setFieldValue(
      key,
      values[key].filter((doc) => doc.id !== id)
    );
  };

  const handleInsStatusByEffecDate = (date) => {
    setValues({
      ...values,
      effDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'effDate')
    });
  };

  const handleInsStatusByExpDate = (date) => {
    setValues({
      ...values,
      expDate: fDateZeroTime(date),
      status: getInsStatusByDate(date, values, 'expDate')
    });
  };

  const isManual = option === IMPORT_OPTIONS.manual;

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={isManual || step === 1} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>{`Add New Insurance${isManual ? ' (Umbrella)' : ''}`}</DialogTitle>
          <Divider />
          <DialogContent>
            {step === 1 ? (
              <RadioGroup name="radio-buttons-group" value={option} onChange={(e) => setOption(e.target.value)}>
                <FormControlLabel value={IMPORT_OPTIONS.manual} control={<Radio />} label="Add Manually" />
                <FormControlLabel value={IMPORT_OPTIONS.carrier} control={<Radio />} label="Import From Carrier" />
              </RadioGroup>
            ) : (
              isManual && (
                <Stack spacing={2}>
                  {/* Top section - identify policy, select risk */}
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Policy Type"
                      {...getFieldProps('policyType')}
                      onChange={(e) => setFieldValue('policyType', e.target.value)}
                      error={Boolean(touched.policyType && errors.policyType)}
                      helperText={touched.policyType && errors.policyType}
                    >
                      {IN_UM_TYPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      {...getFieldProps('policyNumber')}
                      fullWidth
                      type="text"
                      label="Policy Number"
                      error={Boolean(touched.policyNumber && errors.policyNumber)}
                      helperText={touched.policyNumber && errors.policyNumber}
                    />
                    <TextFieldWithHover
                      {...getFieldProps('nickName')}
                      fullWidth
                      type="string"
                      label="Policy Name"
                      info="Input the policy name"
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="primaryContact"
                      label="Primary Contact"
                      values={values.primaryContact}
                      setFieldValue={setFieldValue}
                      isPrimary={true}
                    />
                    <TextField
                      {...getFieldProps('carrier')}
                      fullWidth
                      type="text"
                      label="Insurance Carrier"
                      error={Boolean(touched.carrier && errors.carrier)}
                      helperText={touched.carrier && errors.carrier}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      {...getFieldProps('broker')}
                      fullWidth
                      type="text"
                      label="Brokerage"
                      error={Boolean(touched.broker && errors.broker)}
                      helperText={touched.broker && errors.broker}
                    />
                    <TextField
                      {...getFieldProps('issuingCompany')}
                      fullWidth
                      type="text"
                      label="Issuing Company"
                      error={Boolean(touched.issuingCompany && errors.issuingCompany)}
                      helperText={touched.issuingCompany && errors.issuingCompany}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={isLoading}
                      fullWidth
                      multiple
                      options={filteredRisk}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.nickName || option.id}
                      value={filteredRisk.filter((item) => values.risks.some((value) => value.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'risks',
                          value.map((item) => ({id: item.id, type: item.type}))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {option.nickName || option.id}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Risk" placeholder="Select Risk" />}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2}>
                    <DatePicker
                      fullWidth
                      label="Effective Date"
                      {...getFieldProps('effDate')}
                      onChange={handleInsStatusByEffecDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={Boolean(touched.effDate && errors.effDate)}
                          helperText={touched.effDate && errors.effDate}
                        />
                      )}
                    />
                    <DatePicker
                      fullWidth
                      label="Expiration Date*"
                      {...getFieldProps('expDate')}
                      onChange={handleInsStatusByExpDate}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={Boolean(touched.expDate && errors.expDate)}
                          helperText={touched.expDate && errors.expDate}
                        />
                      )}
                    />
                    <StatusLabel status={values.status} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <ContactSelect
                      clientId={clientId}
                      keyValue="namedInsured"
                      label="Named Insured"
                      values={values.namedInsured}
                      setFieldValue={setFieldValue}
                    />
                    <ContactSelect
                      clientId={clientId}
                      keyValue="additionalInsured"
                      label="Additional Insured"
                      values={values.additionalInsured}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>

                  {/* Middle section - coverage limits */}
                  <Divider />
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Premium"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('premium')}
                      onChange={(e) => setFieldValue('premium', parseFloat(e.target.values.value))}
                    />
                    <NumberFormat
                      fullWidth
                      label="Excess Liability"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('excessLiability')}
                      onChange={(e) => setFieldValue('excessLiability', parseFloat(e.target.values.value))}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Excess Uninsured Motorist"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('excessUninsuredMotorist')}
                      onChange={(e) => setFieldValue('excessUninsuredMotorist', parseFloat(e.target.values.value))}
                    />
                    <NumberFormat
                      fullWidth
                      label="Excess Third Party Liability"
                      prefix="$"
                      placeholder="$0.00"
                      {...getFieldProps('excessThirdPartyLiability')}
                      onChange={(e) => setFieldValue('excessThirdPartyLiability', parseFloat(e.target.values.value))}
                    />
                  </Stack>
                  <Box sx={{width: '50%'}}>
                    <FormControlLabel
                      control={
                        <Switch
                          {...getFieldProps('employmentPracticesLiability')}
                          checked={values.employmentPracticesLiability}
                        />
                      }
                      label="Employment Practices Liability"
                      labelPlacement="start"
                    />
                  </Box>
                  {values.employmentPracticesLiability && (
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                      <NumberFormat
                        fullWidth
                        label="Per Occurance"
                        prefix="$"
                        placeholder="$0.00"
                        {...getFieldProps('perOccurrence')}
                        onChange={(e) => setFieldValue('perOccurrence', parseFloat(e.target.values.value))}
                      />
                      <NumberFormat
                        fullWidth
                        label="Annual Aggregate"
                        prefix="$"
                        placeholder="$0.00"
                        {...getFieldProps('annualAggregate')}
                        onChange={(e) => setFieldValue('annualAggregate', parseFloat(e.target.values.value))}
                      />
                      <NumberFormat
                        fullWidth
                        label="Retention"
                        prefix="$"
                        placeholder="$0.00"
                        {...getFieldProps('retention')}
                        onChange={(e) => setFieldValue('retention', parseFloat(e.target.values.value))}
                      />
                    </Stack>
                  )}
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      {...getFieldProps('status')}
                      onChange={(e) => setFieldValue('status', e.target.value)}
                    >
                      {INSURANCE_STATUS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>

                  {/* Bottom section */}
                  <Divider />
                  <Stack spacing={2}>
                    <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={policies}
                      ListboxProps={{sx: {maxHeight: '160px'}}}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        `${capitalCase(option.type)} - ${option.policyNumber} - ${option.nickName}`
                      }
                      value={policies.filter((item) => values.rPolicies.some((policy) => policy.id === item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rPolicies',
                          value.map((item) => pick(item, ['id', 'type']))
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {capitalCase(option.type)} - {option.policyNumber} - {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Policies" />}
                    />
                    <Autocomplete
                      loading={inLoading}
                      fullWidth
                      multiple
                      options={CLAIMS}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        `${fDate(option.dateOfLoss)} - ${option.policyNumber} - ${capitalCase(option.policyType)} - ${
                          option.nickName
                        }`
                      }
                      value={CLAIMS.filter((item) => values.rClaims.includes(item.id))}
                      onChange={(e, value) =>
                        setFieldValue(
                          'rClaims',
                          value.map((item) => item.id)
                        )
                      }
                      renderOption={(props, option, {selected}) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox checked={selected} />
                            {fDate(option.dateOfLoss)} - {option.policyNumber} - {capitalCase(option.policyType)} -{' '}
                            {option.nickName}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Related Claims" />}
                    />
                  </Stack>
                  <Box>
                    <Typography variant="h6">Policy Documents</Typography>
                    <UploadMultiFile
                      showPreview={false}
                      files={files.policyDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'policyDocs')}
                      onRemove={(arg) => handleRemove(arg, 'policyDocs')}
                      onRemoveAll={() => handleRemoveAll('policyDocs')}
                      documentType="policy"
                      subType="umbrella"
                      fileType="policyDocs"
                      onUpload={(arg) => handleUpload(arg, 'policyDocs')}
                    />
                    <FileEditor files={values.policyDocs} deleteFileFunc={(id) => handleDeleteFile(id, 'policyDocs')} />
                  </Box>
                </Stack>
              )
            )}
          </DialogContent>
          {step === 1 ? (
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  handleClose();
                  setStep(1);
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={() => setStep(2)}>
                Next
              </LoadingButton>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => setStep(1)} color="inherit">
                Back
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
                Submit
              </LoadingButton>
            </DialogActions>
          )}
        </Dialog>
      </Form>
      {step === 2 && option === IMPORT_OPTIONS.carrier && (
        <DisplayInsuranceList handleReset={() => setStep(1)} handleClose={handleClose} />
      )}
    </FormikProvider>
  );
}
