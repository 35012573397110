// material
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {useState} from 'react';
import PropTypes from 'prop-types';
import FileItem from 'src/components/FileItem';

// ----------------------------------------------------------------------

FileEditor.propTypes = {
  files: PropTypes.array,
  deleteFileFunc: PropTypes.func,
  canDelete: PropTypes.bool
};

export default function FileEditor({files, deleteFileFunc, deletables, canDelete = true}) {
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
    title: null
  });

  const promptDeleteFile = (id, title) => () => {
    if (canDelete)
      setDeleteModal({
        id,
        title,
        isOpen: true
      });
  };

  const handleClose = () => {
    setDeleteModal({
      isOpen: false,
      id: null,
      title: null
    });
  };

  const handleDeleteFile = () => {
    if (deletables) deletables((prevFiles) => [...prevFiles, deleteModal.id]);
    deleteFileFunc(deleteModal.id);
    setDeleteModal({
      isOpen: false,
      id: null,
      title: null
    });
  };

  const renderContent = () => {
    if (!files || files?.length === 0) {
      return;
    }

    return (
      <CardContent sx={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
        {files?.map((file) => (
          <FileItem
            key={file.id}
            fileName={file.documentTitle}
            fileUrl={file.documentLink}
            onClick={promptDeleteFile(file.id, file.documentTitle)}
            deletable={canDelete}
          />
        ))}
      </CardContent>
    );
  };

  return (
    <>
      <Card sx={{margin: '10px 0'}}>{renderContent()}</Card>
      <Dialog
        open={deleteModal.isOpen}
        onClose={handleClose}
        aria-labelledby="delete-file-confirmation"
        aria-describedby="delete-file-description"
      >
        <DialogTitle id="delete-file-confirmation">{'Delete the document?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-file-description">
            Are you sure you want to delete the file {deleteModal.title} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDeleteFile} autoFocus color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
