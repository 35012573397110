import {useEffect, useState} from 'react';
import {isString} from 'lodash';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {Icon} from '@iconify/react';
import {useSnackbar} from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import {AnimatePresence} from 'framer-motion';
// mui
import {alpha, styled} from '@mui/material/styles';
import {Box, CircularProgress, Paper, Typography} from '@mui/material';
import {MIconButton} from '../@material-extend';
//redux
import {useSelector} from 'react-redux';
//hooks
import useAuth from 'src/hooks/useAuth';
// api
import axios from 'src/utils/axios';
// utils
import {fData} from '../../utils/formatNumber';
// assets
import {UploadIllustration} from '../../assets';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({theme}) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer'
  },
  [theme.breakpoints.up('md')]: {textAlign: 'left', flexDirection: 'row'}
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  small: PropTypes.bool,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  onUpload: PropTypes.func,
  documentType: PropTypes.string,
  autoUpload: PropTypes.bool,
  previewImage: PropTypes.string
};

export default function UploadSingleFile({
  small = false,
  error,
  file,
  sx,
  onUpload,
  documentType,
  autoUpload = false,
  previewImage,
  onRemove,
  ...other
}) {
  const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({
    multiple: false,
    ...other
  });

  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const {list} = useSelector((state) => state.client);
  const {members} = useSelector((state) => state.familyGroup);

  const clientId = (isAdvisor ? list : members).find((item) => item.show)?.id || '';
  const memberSelected = members.filter((m) => m.show && m.id !== user.userId);

  const fileVaultUploadApi =
    isAdvisor || memberSelected.length === 1
      ? `/advisor/file-vault/upload?userId=${clientId}&isFamilyGroup=${!isAdvisor}`
      : 'file-vault/upload';

  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({file, errors}) => {
        const {path, size} = file;
        return (
          <Box key={path} sx={{my: 1}}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  const handleUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('documentTitle', '');
      formData.append('documentType', documentType);
      formData.append('post', file);
      const uploadedFile = await axios.post(fileVaultUploadApi, formData).then((resp) => {
        setLoading(false);
        enqueueSnackbar('File Added Success', {
          variant: 'success'
        });
        return resp.data;
      });
      if (onUpload) {
        onUpload(uploadedFile);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.message ?? 'File upload error', {
        variant: 'error'
      });
    }
  };

  function isBlobURL(url) {
    if(url){
      const parsedURL = new URL(url);
      return parsedURL.protocol === 'blob:';
    }
    return false;
  }

  useEffect(() => {
    setPreview((prev) => {
      if (prev) {
        URL.revokeObjectURL(prev);
      }
      if (file){
        debugger;
        const result=(!isString(file) ? !isBlobURL(file.preview) : !isBlobURL(file)) ? file : URL.createObjectURL(file);
        return result;
      } 
    });
  }, [file]);

  useEffect(() => {
    setPreview(previewImage);
  }, [previewImage]);

  useEffect(() => {
    if (onUpload && file?.path) handleUpload();
    // eslint-disable-next-line
  }, [file?.path]);

  return (
    <Box sx={{width: '100%', ...sx}}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && {opacity: 0.72}),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          }),
          ...(file && {padding: '12% 0'})
        }}
      >
        {loading ? (
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px'}}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <input {...getInputProps()} />
            {!small && !previewImage && <UploadIllustration sx={{width: 220}} />}
            <Box sx={{p: 3, ml: {md: 2}}}>
              <Typography gutterBottom variant="h5">
                Drop or Select file
              </Typography>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Drop files here or click&nbsp;
                <Typography variant="body2" component="span" sx={{color: 'primary.main', textDecoration: 'underline'}}>
                  browse
                </Typography>
                &nbsp;thorough your machine
              </Typography>
            </Box>
            {(file || preview) && (
              <Box
                component="img"
                alt="file preview"
                src={file ? (isString(file) ? file : file.preview || preview) : preview}
                sx={{
                  top: 8,
                  borderRadius: 1,
                  position: 'absolute',
                  width: 'calc(100% - 16px)',
                  height: 'calc(100% - 16px)'
                }}
              />
            )}
          </>
        )}
      </DropZoneStyle>
      {fileRejections.length > 0 && <ShowRejectionItems />}

      <AnimatePresence>
        {(file?.preview || file) ? (
          <Box
            sx={{
              p: 0,
              m: 0.5,
              width: 80,
              height: 80,
              borderRadius: 1.5,
              overflow: 'hidden',
              position: 'relative',
              display: 'inline-flex'
            }}
          >
            <Paper
              variant="outlined"
              component="img"
              src={isString(file) ? file : file.preview}
              sx={{width: '100%', height: '100%', objectFit: 'cover', position: 'absolute'}}
            />
            <Box sx={{top: 6, right: 6, position: 'absolute'}}>
              <MIconButton
                size="small"
                onClick={() => onRemove()}
                sx={{
                  p: '2px',
                  color: 'common.white',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                  }
                }}
              >
                <Icon icon={closeFill} />
              </MIconButton>
            </Box>
          </Box>
        ) : null}
      </AnimatePresence>
    </Box>
  );
}
