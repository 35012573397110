import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
import {getDataEntries} from 'src/utils/advisor';

// ----------------------------------------------------------------------

const initialState = {
  initiated: false,
  isLoading: false,
  isAssLoading: false,
  isPolLoading: false,
  adCoInitiated: false,
  adAsInitiated: false,
  adPoInitiated: false,
  adOrInitiated: false,
  adLeInitiated: false,
  adEvInitiated: false,
  adFiInitiated: false,
  error: null,
  list: [],
  contacts: [],
  assetChartData: {
    realEstate: [],
    collection: [],
    automobile: [],
    watercraft: [],
    aircraft: [],
    equine: []
  },
  insuranceChartData: {
    aircraft: [],
    automobile: [],
    claim: [],
    collection: [],
    equine: [],
    misc: [],
    realEstate: [],
    umbrella: [],
    watercraft: []
  },
  orgChartData: {
    board: [],
    club: [],
    entity: [],
    trust: []
  },
  asset: {
    realEstate: [],
    collection: [],
    automobiles: [],
    watercraft: [],
    aircraft: [],
    equine: []
  },
  insurance: {
    aircraft: [],
    automobile: [],
    claim: [],
    collection: [],
    equine: [],
    misc: [],
    realEstate: [],
    umbrella: [],
    watercraft: []
  },
  org: {
    board: [],
    club: [],
    entity: [],
    trust: []
  },
  ledger: [],
  events: [],
  files: []
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // LOADING Assets
    setAssLoading(state, action) {
      state.isAssLoading = action.payload;
    },

    // LOADING Policies
    setPolLoading(state, action) {
      state.isPolLoading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLIENTS
    setList(state, action) {
      state.isLoading = false;
      state.list = action.payload;
      state.initiated = true;
    },

    // GET Contacts
    setAdvisorContacts(state, action) {
      state.isLoading = false;
      state.adCoInitiated = true;
      state.contacts = action.payload;
    },

    setAdvisorAssets(state, action) {
      state.isAssLoading = false;
      state.adAsInitiated = true;
      state.asset = action.payload;
    },

    setAdvisorAssetCharts(state, action) {
      state.assetChartData = action.payload;
    },

    setAdvisorInsurence(state, action) {
      state.isPolLoading = false;
      state.adPoInitiated = true;
      state.insurance = action.payload;
    },

    setAdvisorInsuranceCharts(state, action) {
      state.insuranceChartData = action.payload;
    },

    setAdvisorOrganizations(state, action) {
      state.isLoading = false;
      state.adOrInitiated = true;
      state.org = action.payload;
    },

    setAdvisorOrgCharts(state, action) {
      state.orgChartData = action.payload;
    },

    setAdvisorLedger(state, action) {
      state.isLoading = false;
      state.adLeInitiated = true;
      state.ledger = action.payload;
    },

    setAdvisorEvent(state, action) {
      state.isLoading = false;
      state.adEvInitiated = true;
      state.events = action.payload;
    },

    setAdvisorFiles(state, action) {
      state.isLoading = false;
      state.adFiInitiated = true;
      state.files = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const {setList} = slice.actions;

// ----------------------------------------------------------------------

export function getClients() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/advisor/client');
      const list = response.data.map((item) => ({...item, show: true})).filter((item) => !!item.email);
      dispatch(slice.actions.setList(list));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function updateClients(list) {
  return (dispatch) => {
    dispatch(slice.actions.setList(list));
  };
}

// add favorite client
export function addFavoriteClient(data, list) {
  return async (dispatch) => {
    try {
      dispatch(updateClients(list));
      await axios.post('/advisor/client/favorite', data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

// NOTE: get connected clients' contact data

export function addNewClient(payload, clientId, activeStep) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.post(`/advisor/new-client?userId=${clientId}&step=${activeStep}`, payload);
      const updatedData = await axios.get(`/advisor/user-contacts/all?isFamilyGroup=${false}`);
      dispatch(slice.actions.setAdvisorContacts(updatedData.data));

      return result;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getAdvisorContacts(isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/advisor/user-contacts/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorContacts(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function addAdvisorContact(payload, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.post(
        `/advisor/user-contacts?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
        payload
      );
      const updatedData = await axios.get(`/advisor/user-contacts/all?isFamilyGroup=${isFamilyGroup}`);
      dispatch(slice.actions.setAdvisorContacts(updatedData.data));

      return result;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function updateAdvisorContact(payload, id, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.put(
        `/advisor/user-contacts/${id}?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
        payload
      );
      const updatedData = await axios.get(`/advisor/user-contacts/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorContacts(updatedData.data));
      return result;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function deleteAdvisorContact(id, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.delete(
        `/advisor/user-contacts/${id}?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`
      );
      const updatedData = await axios.get(`/advisor/user-contacts/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorContacts(updatedData.data));
      return result;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// NOTE: get connected clients' asset data
export function getAdvisorAssets(isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.setAssLoading(true));
    try {
      const response = await axios.get(`/advisor/asset/all?isFamilyGroup=${isFamilyGroup}`);
      const [entries, topChart] = getDataEntries(response.data);

      dispatch(slice.actions.setAdvisorAssets(entries));
      dispatch(slice.actions.setAdvisorAssetCharts(topChart));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// NOTE: add connected clients' asset data
export function addClientAsset(payload, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.setAssLoading(true));

    try {
      const tab = payload.type;
      if (tab === 'realEstate') {
        const result = await axios.post(
          `advisor/asset/real-estate?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      } else if (tab === 'collection') {
        const result = await axios.post(
          `/advisor/asset/collection?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      } else if (tab === 'automobile') {
        const result = await axios.post(
          `/advisor/asset/automobile?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      } else if (tab === 'watercraft') {
        const result = await axios.post(
          `/advisor/asset/watercraft?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      } else if (tab === 'aircraft') {
        const result = await axios.post(
          `/advisor/asset/aircraft?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      } else if (tab === 'equine') {
        const result = await axios.post(
          `/advisor/asset/equine?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(slice.actions.setAssLoading(false));
    }
  };
}

// NOTE: get connected clients' insurance data
export function getAdvisorInsurance(isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.setPolLoading(true));
    try {
      const response = await axios.get(`/advisor/insurance/all?isFamilyGroup=${isFamilyGroup}`);
      const [entries, topChart] = getDataEntries(response.data);

      dispatch(slice?.actions?.setAdvisorInsurence?.(entries));
      dispatch(slice?.actions?.setAdvisorInsuranceCharts?.(topChart));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// NOTE: add connected clients' insurance data
export function addClientInsurance(payload, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    const tab = payload.type;
    dispatch(slice.actions.setPolLoading(true));
    try {
      if (tab === 'realEstate') {
        const result = await axios.post(
          `/advisor/insurance/real-estate?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      } else if (tab === 'collection') {
        const result = await axios.post(
          `/advisor/insurance/collection?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      } else if (tab === 'automobile') {
        const result = await axios.post(
          `/advisor/insurance/automobile?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      } else if (tab === 'watercraft') {
        const result = await axios.post(
          `/advisor/insurance/watercraft?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      } else if (tab === 'aircraft') {
        const result = await axios.post(
          `/advisor/insurance/aircraft?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      } else if (tab === 'umbrella') {
        const result = await axios.post(
          `/advisor/insurance/umbrella?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      } else if (tab === 'misc') {
        const result = await axios.post(
          `/advisor/insurance/misc?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      } else if (tab === 'equine') {
        const result = await axios.post(
          `/advisor/insurance/equine?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      } else if (tab === 'claim') {
        const result = await axios.post(
          `/advisor/insurance/claim?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );
        return result;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(slice.actions.setPolLoading(false));
    }
  };
}

// NOTE: get connected clients' org data
export function getAdvisorOrgs(isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/advisor/organization/all?isFamilyGroup=${isFamilyGroup}`);
      const [entries, topChart] = getDataEntries(response.data);

      dispatch(slice.actions.setAdvisorOrganizations(entries));
      dispatch(slice.actions.setAdvisorOrgCharts(topChart));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// NOTE: add connected clients' org data
export function addClientOrg(payload, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    const tab = payload.type;
    dispatch(slice.actions.startLoading());
    try {
      if (tab === 'board') {
        const result = await axios.post(
          `/advisor/organization/board?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      } else if (tab === 'entity') {
        const result = await axios.post(
          `/advisor/organization/entity?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      } else if (tab === 'trust-estate') {
        const result = await axios.post(
          `/advisor/organization/trust-estate?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      } else if (tab === 'club') {
        const result = await axios.post(
          `/advisor/organization/club?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
          payload
        );

        return result;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// NOTE: get connected clients' ledger data
export function getAdvisorLedger(isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/advisor/ledger/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorLedger(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// NOTE: add new ledger for client
export function addClientLedger(payload, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    try {
      const result = await axios.post(`/advisor/ledger?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`, payload);
      const response = await axios.get(`/advisor/ledger/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorLedger(response.data));
      return result;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      return err;
    }
  };
}

// NOTE: get connected clients' event data
export function getAdvisorEvent(isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/advisor/calendar-event/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorEvent(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// NOTE: add new event for client
export function addClientEvent(payload, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    try {
      const result = await axios.post(
        `/advisor/calendar-event?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
        payload
      );
      const response = await axios.get(`/advisor/calendar-event/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorEvent(response.data));
      return result;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      return err;
    }
  };
}

// NOTE: update event for client
export function updateClientEvent(eventId, payload, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    try {
      const result = await axios.put(
        `/advisor/calendar-event/${eventId}?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
        payload
      );
      const response = await axios.get(`/advisor/calendar-event/all?isFamilyGroup=${isFamilyGroup}`);
      dispatch(slice.actions.setAdvisorEvent(response.data));

      return result;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      return err;
    }
  };
}

// NOTE: update event for client
export function deleteClientEvent(eventId, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    try {
      const result = await axios.delete(
        `/advisor/calendar-event/${eventId}?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`
      );
      const response = await axios.get(`/advisor/calendar-event/all?isFamilyGroup=${isFamilyGroup}`);
      dispatch(slice.actions.setAdvisorEvent(response.data));

      return result;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      return err;
    }
  };
}

// NOTE: get connected clients' file data
export function getAdvisorFiles(isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/advisor/file-vault/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorFiles(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// NOTE: add new file for client
export function addClientFile(payload, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    try {
      const result = await axios.post(
        `/advisor/file-vault/upload?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`,
        payload
      );
      const response = await axios.get(`/advisor/file-vault/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorFiles(response.data));
      return result;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      return err;
    }
  };
}

// NOTE: delete connected clients' file data
export function deleteAdvisorFile(fileId, clientId, isFamilyGroup = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.delete(
        `/advisor/file-vault/${fileId}?userId=${clientId}&isFamilyGroup=${isFamilyGroup}`
      );
      const response = await axios.get(`/advisor/file-vault/all?isFamilyGroup=${isFamilyGroup}`);

      dispatch(slice.actions.setAdvisorFiles(response.data));
      return result;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
