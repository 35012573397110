import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Stack,
  TextField,
  Typography,
  DialogContentText
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addAsset} from 'src/redux/slices/asset';
import {addClientAsset} from 'src/redux/slices/client';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {getNickName} from 'src/utils/formatNickname';
// components
import {UploadMultiFile} from 'src/components/upload';
import NumberFormat from 'src/components/NumberFormat';
import FileEditor from 'src/components/FileEditor';
import {
  AddressFields,
  ContactSelect,
  FormikErrorNotification,
  TextFieldWithHover
} from 'src/components/_dashboard/common';
// constants
import {
  RE_TYPE,
  RE_CONSTRUCTION_TYPE,
  ASSET_STATUS,
  SAFETY_FEATURES,
  HURRICANE_PROTECTION,
  EARTHQUAKE_PROTECTION,
  FLOOD_ZONE,
  OCCUPANCY_TYPE,
  ROOF_SHAPE,
  ROOF_CONNECTION,
  FOUNDATION_TYPE
} from 'src/constants/dropdown';
import {fDateZeroTime} from 'src/utils/formatTime';
//utils
import {getFormattedAddress} from 'src/utils/stringFormat';
import HazardDataDisplay from 'src/components/HazardDataDisplay';
import {getHazardHubRecords, getZillowPublicRecords} from 'src/utils/getApiData';

// ----------------------------------------------------------------------

const TYPE = 'realEstate';
const assetDocs = [
  'imageFiles',
  'settlementDocs',
  'rValueDocs',
  'flElCertDocs',
  'huReportDocs',
  'earthquakeDocs',
  'leaseDocs',
  'loanDocs',
  'easementAndLegalDocs',
  'alarmAndSecurityCertificate'
];

RealEstateDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  inLoading: PropTypes.bool,
  policies: PropTypes.array.isRequired
};

export default function RealEstateDialog({handleClose, clientId, open, inLoading = false, policies = []}) {
  const {user} = useAuth();
  const isAdvisor = user && user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [isImportedFromZillow, setIsImportedFromZillow] = useState(false);
  const [isImportedFromHazardHub, setIsImportedFromHazardHub] = useState(false);
  const [isOpenZillowConfirmModal, setIsOpenZillowConfirmModal] = useState(false);
  const [zillowImportLoading, setZillowImportLoading] = useState(false);
  const [hazardHubImportLoading, setHazardHubImportLoading] = useState(false);

  const [address, setAddress] = useState({});
  const [files, setFiles] = useState(assetDocs.reduce((res, key) => ({...res, [key]: []}), {}));

  const AddSchema = Yup.object().shape({
    imageFiles: Yup.array(),
    occupancyType: Yup.string().required('Please select Occupancy Type'),
    realEstateAddressId: Yup.string().required('Please select an address'),
    datePurchased: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lastRenovationDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lzDeFlDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lEleCertDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lastWindMitigationInceptionDate: Yup.date().nullable().min(new Date(1900, 0, 1)),
    lastRetrofitUpdateDate: Yup.date().nullable().min(new Date(1900, 0, 1))
  });

  // formik
  const formik = useFormik({
    initialValues: {
      realEstateType: 'land', // Real Estate Type
      occupancyType: '',
      nickName: '',
      marketValue: '',
      street: '',
      apt: '',
      city: '',
      state: '',
      zipCode: '',
      realEstateAddressId: '',
      conType: '',
      builtYear: '',
      owner: [], // Deeded Owner
      addInterest: [], // Additional Interest,
      showHurricaneData: false,
      showEarthquakeData: false,
      showFloodData: false,
      datePurchased: null,
      purchasePrice: '',
      lastRenovationDate: null,
      lastRenovationValue: '',
      safetyFeatures: {}, // Satefy Features
      hazardData: {}, // Hazard Data
      otherSafetyFeature: '',
      huProtection: '', // Hurricane Protection
      roofShape: '',
      roofConnection: '',
      lastWindMitigationInceptionDate: null,
      lastRetrofitUpdateDate: null,
      eaProtection: '', // Earthquake Protection
      foundationType: '',
      flZone: '', // Flood Zone
      flElevation: '', // Flood Elevation
      bdElevation: '', // Building Elevation
      lEleCertDate: null,
      lzDeFlDate: null, // Last Zone Determination Flood Date
      homeUsage: '', // Home Usage
      lPlace: false, // Rent Lease Placed
      lReviewed: false, // Lease Reviewed
      loans: '', // Loans
      liens: '', // Liens
      proTax: '', // Property Tax
      assetStatus: 'active',
      insured: false,
      insuredValue: '', // Insured Value
      rPolicies: [], // Related Policies
      note: '',
      imageFiles: [],
      settlementDocs: [],
      rValueDocs: [], // Replacement Value Docs
      flElCertDocs: [], // Flood Elevation Cert Docs
      huReportDocs: [], // Hurricane Report Docs
      earthquakeDocs: [], // Earthquake Docs
      loanDocs: [], // Loan Docs
      leaseDocs: [], // Lease Docs
      easementAndLegalDocs: [], // Easement and Legal Docs
      alarmAndSecurityCertificate: [] // Alarm And Security Certificate
    },
    validationSchema: AddSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        values.safetyFeatures[values.otherSafetyFeature] = true;
        const payload = {
          ...values,
          nickName: values.nickName || getNickName(address, 'realEstate'),
          type: TYPE,
          verifiedByZillow: isImportedFromZillow,
          verifiedByHH: isImportedFromHazardHub
        };
        const res = !!clientId
          ? await dispatch(addClientAsset(payload, clientId, !isAdvisor))
          : await dispatch(addAsset(payload));
        setSubmitting(false);

        if (res && res?.data) {
          enqueueSnackbar('New Asset has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/asset/realEstate/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/asset/realEstate/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Asset creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, resetForm, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  const handleDropMultiFile = useCallback((acceptedFiles, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    }));
  }, []);

  const handleRemoveAll = (key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: []
    }));
  };

  const handleRemove = (file, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: files[key].filter((_file) => _file !== file)
    }));
  };

  const handleUpload = useCallback(
    (uploadedFiles, key) => {
      setFieldValue(key, [...values[key], ...uploadedFiles]);
      setFiles((allFiles) => ({
        ...allFiles,
        [key]: []
      }));
    },
    [setFieldValue, values]
  );

  const handleDeleteFile = (id, key) => {
    setFieldValue(
      key,
      values[key].filter((doc) => doc.id !== id)
    );
  };

  const zillowPublicRecords = async () => {
    await getZillowPublicRecords(
      values,
      setZillowImportLoading,
      getFormattedAddress(address),
      enqueueSnackbar,
      setFieldValue,
      setIsImportedFromZillow
    );
  };

  // fetch hazardhub records
  const hazardHubRecords = async () => {
    await getHazardHubRecords(
      setHazardHubImportLoading,
      address,
      enqueueSnackbar,
      setFieldValue,
      setIsImportedFromHazardHub
    );
  };

  // remove hazard hub data
  const handleRemoveHazardData = () => {
    setFieldValue('hazardData', {});
    setIsImportedFromHazardHub(false); // remove verified tag
  };

  const handleCancelClick = () => {
    resetForm();
    handleClose();
  };

  return (
    <FormikProvider value={formik}>
      <FormikErrorNotification />
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Asset (Real Estate)</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Real Estate Type"
                  {...getFieldProps('realEstateType')}
                  onChange={(e) => {
                    setFieldValue('realEstateType', e.target.value);
                    setFieldValue('occupancyType', '');
                  }}
                  helperText="Please select real estate type."
                >
                  {RE_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  label="Occupancy"
                  {...getFieldProps('occupancyType')}
                  onChange={(e) => setFieldValue('occupancyType', e.target.value)}
                  error={Boolean(touched.occupancyType && errors.occupancyType)}
                  helperText={(touched.occupancyType && errors.occupancyType) || 'Please select Occupancy type.'}
                >
                  {(values.realEstateType === 'rented'
                    ? [...new Set([].concat(...Object.values(OCCUPANCY_TYPE).map((item) => item)))]
                    : /* if rented then all occupancy type is possible */
                      OCCUPANCY_TYPE[values.realEstateType]
                  ).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              {['other-family-member-occupied', 'rented-to-others'].includes(values.occupancyType) && (
                <Stack direction={{xs: 'column', md: 'row-reverse'}} spacing={2}>
                  <Stack sx={{p: 1}} spacing={1}>
                    <FormControlLabel
                      control={<Checkbox {...getFieldProps('lReviewed')} checked={values.lReviewed} />}
                      label="Lease Reviewed"
                      labelPlacement="start"
                    />
                  </Stack>
                  <Stack sx={{p: 1}} spacing={1}>
                    <FormControlLabel
                      control={<Checkbox {...getFieldProps('lPlace')} checked={values.lPlace} />}
                      label="Lease Placed"
                      labelPlacement="start"
                    />
                  </Stack>
                </Stack>
              )}
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextFieldWithHover
                  {...getFieldProps('nickName')}
                  fullWidth
                  type="string"
                  label="Asset Name"
                  info="Input the asset name"
                />
                <NumberFormat
                  fullWidth
                  label="Market Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('marketValue')}
                  onChange={(e) => setFieldValue('marketValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <ContactSelect
                  clientId={clientId}
                  keyValue="owner"
                  label="Owner"
                  values={values.owner}
                  setFieldValue={setFieldValue}
                />
                <ContactSelect
                  clientId={clientId}
                  keyValue="addInterest"
                  label="Additional Interest"
                  values={values.addInterest}
                  setFieldValue={setFieldValue}
                />
              </Stack>
              <AddressFields
                isRealEstate={true}
                addId={values.realEstateAddressId}
                zillowPublicRecords={zillowPublicRecords}
                zillowImportLoading={zillowImportLoading}
                hazardHubRecords={hazardHubRecords} //
                hazardHubImportLoading={hazardHubImportLoading} //
                handleChange={(id, address) => {
                  setFieldValue('realEstateAddressId', id);
                  setAddress(address);
                }}
                inputProps={{
                  error: Boolean(touched.realEstateAddressId && errors.realEstateAddressId),
                  helperText: touched.realEstateAddressId && errors.realEstateAddressId
                }}
              />
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <NumberFormat
                  fullWidth
                  label="Property Tax"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('proTax')}
                  onChange={(e) => setFieldValue('proTax', parseFloat(e.target.values.value))}
                />
                <NumberFormat
                  fullWidth
                  label="Square Footage"
                  placeholder="0.00"
                  {...getFieldProps('squareFootage')}
                  onChange={(e) => setFieldValue('squareFootage', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <NumberFormat
                  fullWidth
                  label="Loans"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('loans')}
                  onChange={(e) => setFieldValue('loans', parseFloat(e.target.values.value))}
                />
                <NumberFormat
                  fullWidth
                  label="Liens"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('liens')}
                  onChange={(e) => setFieldValue('liens', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <DatePicker
                  disableFuture
                  fullWidth
                  label="Date Purchased"
                  {...getFieldProps('datePurchased')}
                  onChange={(date) => setFieldValue('datePurchased', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
                <NumberFormat
                  fullWidth
                  label="Purchase Price"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('purchasePrice')}
                  onChange={(e) => setFieldValue('purchasePrice', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField {...getFieldProps('builtYear')} fullWidth type="number" label="Built Year" />
                <TextField
                  select
                  fullWidth
                  label="Construction Type"
                  {...getFieldProps('conType')}
                  onChange={(e) => setFieldValue('conType', e.target.value)}
                  helperText="Please select construction type."
                >
                  {RE_CONSTRUCTION_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <DatePicker
                  disableFuture
                  fullWidth
                  label="Last Renovation Date"
                  {...getFieldProps('lastRenovationDate')}
                  onChange={(date) => setFieldValue('lastRenovationDate', fDateZeroTime(date))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
                <NumberFormat
                  fullWidth
                  label="Last Renovation Value"
                  prefix="$"
                  placeholder="$0.00"
                  {...getFieldProps('lastRenovationValue')}
                  onChange={(e) => setFieldValue('lastRenovationValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h6">Safety Features</Typography>
                <Grid container>
                  {SAFETY_FEATURES.map((feat, index) => (
                    <Grid item xs={6} md={4} key={index}>
                      <Stack>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) =>
                                setFieldValue('safetyFeatures', {
                                  ...values.safetyFeatures,
                                  [feat.value]: e.target.checked
                                })
                              }
                              checked={values.safetyFeatures[feat.value] || false}
                            />
                          }
                          label={feat.label}
                          labelPlacement="start"
                        />
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>

              {/* HAZARD DATA */}
              {Object.keys(values.hazardData).length !== 0 && (
                <HazardDataDisplay
                  handleRemoveHazardData={handleRemoveHazardData}
                  hazard_data={values.hazardData}
                  display_type={'Add'}
                />
              )}

              <Stack spacing={2}>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={5}
                  label="Other (Manual Entry)"
                  {...getFieldProps('otherSafetyFeature')}
                />
              </Stack>
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => setFieldValue('showFloodData', e.target.checked)}
                      checked={values.showFloodData || false}
                    />
                  }
                  label="FLOOD DETAILS"
                />
              </Stack>
              {values.showFloodData && (
                <Stack direction="column" spacing={2} sx={{mb: '36px !important'}}>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Flood Zone"
                      {...getFieldProps('flZone')}
                      onChange={(e) => setFieldValue('flZone', e.target.value)}
                    >
                      {FLOOD_ZONE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Flood Elevation"
                      placeholder="0.00"
                      {...getFieldProps('flElevation')}
                      onChange={(e) => setFieldValue('flElevation', parseFloat(e.target.values.value))}
                    />
                    <DatePicker
                      disableFuture
                      fullWidth
                      label="Last Zone Determination Flood Date"
                      {...getFieldProps('lzDeFlDate')}
                      onChange={(date) => setFieldValue('lzDeFlDate', fDateZeroTime(date))}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <NumberFormat
                      fullWidth
                      label="Building Elevation"
                      placeholder="0.00"
                      {...getFieldProps('bdElevation')}
                      onChange={(e) => setFieldValue('bdElevation', parseFloat(e.target.values.value))}
                    />
                    <DatePicker
                      disableFuture
                      fullWidth
                      label="Last Elevation Certificate Date"
                      {...getFieldProps('lEleCertDate')}
                      onChange={(date) => setFieldValue('lEleCertDate', fDateZeroTime(date))}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Stack>
                </Stack>
              )}
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => setFieldValue('showHurricaneData', e.target.checked)}
                      checked={values.showHurricaneData || false}
                    />
                  }
                  label="HURRICANE PROTECTION DETAILS"
                />
              </Stack>
              {values.showHurricaneData && (
                <Stack direction="column" spacing={2} sx={{mb: '36px !important'}}>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Hurricane Protection"
                      {...getFieldProps('huProtection')}
                      onChange={(e) => setFieldValue('huProtection', e.target.value)}
                    >
                      {HURRICANE_PROTECTION.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      fullWidth
                      label="Roof Shape"
                      {...getFieldProps('roofShape')}
                      onChange={(e) => setFieldValue('roofShape', e.target.value)}
                    >
                      {ROOF_SHAPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Roof Connection"
                      {...getFieldProps('roofConnection')}
                      onChange={(e) => setFieldValue('roofConnection', e.target.value)}
                    >
                      {ROOF_CONNECTION.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <DatePicker
                      disableFuture
                      fullWidth
                      label="Last Wind Mitigation Inspection Date"
                      {...getFieldProps('lastWindMitigationInceptionDate')}
                      onChange={(date) => setFieldValue('lastWindMitigationInceptionDate', fDateZeroTime(date))}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Stack>
                </Stack>
              )}
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => setFieldValue('showEarthquakeData', e.target.checked)}
                      checked={values.showEarthquakeData || false}
                    />
                  }
                  label="EARTHQUAKE PROTECTION DETAILS"
                />
              </Stack>
              {values.showEarthquakeData && (
                <Stack direction="column" spacing={2} sx={{mb: '36px !important'}}>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <TextField
                      select
                      fullWidth
                      label="Earthquake Protection"
                      {...getFieldProps('eaProtection')}
                      onChange={(e) => setFieldValue('eaProtection', e.target.value)}
                    >
                      {EARTHQUAKE_PROTECTION.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      fullWidth
                      label="Foundation Type"
                      {...getFieldProps('foundationType')}
                      onChange={(e) => setFieldValue('foundationType', e.target.value)}
                    >
                      {FOUNDATION_TYPE.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <DatePicker
                      disableFuture
                      fullWidth
                      label="Last Retrofit Update"
                      {...getFieldProps('lastRetrofitUpdateDate')}
                      onChange={(date) => setFieldValue('lastRetrofitUpdateDate', fDateZeroTime(date))}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Stack>
                </Stack>
              )}
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Asset Status"
                  {...getFieldProps('assetStatus')}
                  onChange={(e) => setFieldValue('assetStatus', e.target.value)}
                  helperText="Please select asset status."
                >
                  {ASSET_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <Box sx={{width: '100%'}}>
                  <FormControlLabel
                    control={<Switch {...getFieldProps('insured')} checked={values.insured} />}
                    label="Insured"
                    labelPlacement="start"
                  />
                </Box>
                {values.insured && (
                  <NumberFormat
                    fullWidth
                    label="Insured Value"
                    prefix="$"
                    placeholder="$0.00"
                    {...getFieldProps('insuredValue')}
                    onChange={(e) => setFieldValue('insuredValue', parseFloat(e.target.values.value))}
                  />
                )}
              </Stack>
              <Autocomplete
                fullWidth
                multiple
                loading={inLoading}
                options={policies}
                ListboxProps={{sx: {maxHeight: '160px'}}}
                disableCloseOnSelect
                getOptionLabel={(option) => `Real Estate - ${option.policyNumber} - ${option.nickName}`}
                value={policies?.filter?.((item) => values.rPolicies.some((pol) => pol.id === item.id))}
                onChange={(e, value) =>
                  setFieldValue(
                    'rPolicies',
                    value.map((item) => ({id: item.id, type: 'realEstate'}))
                  )
                }
                renderOption={(props, option, {selected}) => {
                  return (
                    <li {...props} key={option.id}>
                      <Checkbox checked={selected} />
                      Real Estate - {option.policyNumber} - {option.nickName}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Related Policies" />}
              />
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Asset Images</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.imageFiles}
                      onDrop={(arg) => handleDropMultiFile(arg, 'imageFiles')}
                      onRemove={(arg) => handleRemove(arg, 'imageFiles')}
                      onRemoveAll={() => handleRemoveAll('imageFiles')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="imageFiles"
                      onUpload={(arg) => handleUpload(arg, 'imageFiles')}
                    />
                    <FileEditor files={values.imageFiles} deleteFileFunc={(id) => handleDeleteFile(id, 'imageFiles')} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Settlement Docs</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.settlementDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'settlementDocs')}
                      onRemove={(arg) => handleRemove(arg, 'settlementDocs')}
                      onRemoveAll={() => handleRemoveAll('settlementDocs')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="settlementDocs"
                      onUpload={(arg) => handleUpload(arg, 'settlementDocs')}
                    />
                    <FileEditor
                      files={values.settlementDocs}
                      deleteFileFunc={(id) => handleDeleteFile(id, 'settlementDocs')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Replacement Value Docs</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.rValueDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'rValueDocs')}
                      onRemove={(arg) => handleRemove(arg, 'rValueDocs')}
                      onRemoveAll={() => handleRemoveAll('rValueDocs')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="rValueDocs"
                      onUpload={(arg) => handleUpload(arg, 'rValueDocs')}
                    />
                    <FileEditor files={values.rValueDocs} deleteFileFunc={(id) => handleDeleteFile(id, 'rValueDocs')} />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Flood Elevation Cert Docs</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.flElCertDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'flElCertDocs')}
                      onRemove={(arg) => handleRemove(arg, 'flElCertDocs')}
                      onRemoveAll={() => handleRemoveAll('flElCertDocs')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="flElCertDocs"
                      onUpload={(arg) => handleUpload(arg, 'flElCertDocs')}
                    />
                    <FileEditor
                      files={values.flElCertDocs}
                      deleteFileFunc={(id) => handleDeleteFile(id, 'flElCertDocs')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Hurricane Report Docs</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.huReportDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'huReportDocs')}
                      onRemove={(arg) => handleRemove(arg, 'huReportDocs')}
                      onRemoveAll={() => handleRemoveAll('huReportDocs')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="huReportDocs"
                      onUpload={(arg) => handleUpload(arg, 'huReportDocs')}
                    />
                    <FileEditor
                      files={values.huReportDocs}
                      deleteFileFunc={(id) => handleDeleteFile(id, 'huReportDocs')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Earthquake Docs</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.earthquakeDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'earthquakeDocs')}
                      onRemove={(arg) => handleRemove(arg, 'earthquakeDocs')}
                      onRemoveAll={() => handleRemoveAll('earthquakeDocs')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="earthquakeDocs"
                      onUpload={(arg) => handleUpload(arg, 'earthquakeDocs')}
                    />
                    <FileEditor
                      files={values.earthquakeDocs}
                      deleteFileFunc={(id) => handleDeleteFile(id, 'earthquakeDocs')}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Loan Docs</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.loanDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'loanDocs')}
                      onRemove={(arg) => handleRemove(arg, 'loanDocs')}
                      onRemoveAll={() => handleRemoveAll('loanDocs')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="loanDocs"
                      onUpload={(arg) => handleUpload(arg, 'loanDocs')}
                    />
                    <FileEditor files={values.loanDocs} deleteFileFunc={(id) => handleDeleteFile(id, 'loanDocs')} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Lease Docs</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.leaseDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'leaseDocs')}
                      onRemove={(arg) => handleRemove(arg, 'leaseDocs')}
                      onRemoveAll={() => handleRemoveAll('leaseDocs')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="leaseDocs"
                      onUpload={(arg) => handleUpload(arg, 'leaseDocs')}
                    />
                    <FileEditor files={values.leaseDocs} deleteFileFunc={(id) => handleDeleteFile(id, 'leaseDocs')} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Easements & Legal Docs</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.easementAndLegalDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'easementAndLegalDocs')}
                      onRemove={(arg) => handleRemove(arg, 'easementAndLegalDocs')}
                      onRemoveAll={() => handleRemoveAll('easementAndLegalDocs')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="easementAndLegalDocs"
                      onUpload={(arg) => handleUpload(arg, 'easementAndLegalDocs')}
                    />
                    <FileEditor
                      files={values.easementAndLegalDocs}
                      deleteFileFunc={(id) => handleDeleteFile(id, 'easementAndLegalDocs')}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box sx={{pl: 1}}>
                    <Typography variant="h6">Alarm & Security Certificate</Typography>
                    <UploadMultiFile
                      size="small"
                      showPreview={false}
                      files={files.alarmAndSecurityCertificate}
                      onDrop={(arg) => handleDropMultiFile(arg, 'alarmAndSecurityCertificate')}
                      onRemove={(arg) => handleRemove(arg, 'alarmAndSecurityCertificate')}
                      onRemoveAll={() => handleRemoveAll('alarmAndSecurityCertificate')}
                      documentType="asset"
                      subType="realEstate"
                      fileType="alarmAndSecurityCertificate"
                      onUpload={(arg) => handleUpload(arg, 'alarmAndSecurityCertificate')}
                    />
                    <FileEditor
                      files={values.alarmAndSecurityCertificate}
                      deleteFileFunc={(id) => handleDeleteFile(id, 'alarmAndSecurityCertificate')}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelClick} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={() => {
                !isImportedFromZillow && !isImportedFromHazardHub ? handleSubmit() : setIsOpenZillowConfirmModal(true);
              }}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>

      <Dialog open={isOpenZillowConfirmModal} onClose={() => setIsOpenZillowConfirmModal(false)}>
        <DialogTitle>{'Ok to accept information pulled from Public Data?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to continue?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenZillowConfirmModal(false)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton onClick={() => handleSubmit()} loading={isSubmitting} autoFocus variant="contained">
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
}
