import {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import {useDropzone} from 'react-dropzone';
import {useSnackbar} from 'notistack';
import roundAddAPhoto from '@iconify/icons-ic/round-add-a-photo';
// material
import {alpha, styled} from '@mui/material/styles';
import {Box, CircularProgress, FormHelperText, Typography, Paper} from '@mui/material';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import {fData} from 'src/utils/formatNumber';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const PHOTO_SIZE = 3145728; // bytes
const FILE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const RootStyle = styled('div')(({theme}) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': {width: '100%', height: '100%'},
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
});

const PlaceholderStyle = styled('div')(({theme}) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': {opacity: 0.72}
}));

const LoadingStyle = styled('div')(({theme}) => ({
  zIndex: 99,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.grey[900], 0.72)
}));

// ----------------------------------------------------------------------

UploadAvatar.propTypes = {
  imageUrl: PropTypes.string,
  caption: PropTypes.node,
  sx: PropTypes.object,
  onDrop: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
};

export default function UploadAvatar({imageUrl, caption, sx, onDrop, onUpload}) {
  const {enqueueSnackbar} = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const checkSize = file.size < PHOTO_SIZE;
      const checkType = FILE_FORMATS.includes(file.type);

      if (!checkSize) {
        setError('size-invalid');
        return;
      } else if (!checkType) {
        setError('type-invalid');
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append('avatar', file);
      return axios
        .post('/profile/avatar', formData)
        .then((response) => {
          const {data} = response;
          if (isMountedRef.current) {
            setLoading(false);
            onUpload(data);
            onDrop(data);
            setError(null);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.message || 'Avatar image upload failed.', {variant: 'error'});
          setLoading(false);
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMountedRef, enqueueSnackbar]
  );

  const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: handleDrop
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        my: 2,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({file, errors}) => {
        const {path, size} = file;
        return (
          <Box key={path} sx={{my: 1}}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <>
      <RootStyle sx={sx}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && {opacity: 0.72}),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter'
            })
          }}
        >
          <input {...getInputProps()} />
          {loading && (
            <LoadingStyle>
              <CircularProgress size={32} thickness={2.4} />
            </LoadingStyle>
          )}
          {imageUrl && <Box component="img" alt="avatar" src={imageUrl} sx={{zIndex: 8, objectFit: 'cover'}} />}
          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(imageUrl && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': {opacity: 0.72}
              })
            }}
          >
            <Box component={Icon} icon={roundAddAPhoto} sx={{width: 24, height: 24, mb: 1}} />
            <Typography variant="caption">{imageUrl ? 'Update photo' : 'Upload photo'}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        {error === 'size-invalid' && (
          <FormHelperText error>{`File is larger than ${fData(PHOTO_SIZE)}`}</FormHelperText>
        )}

        {error === 'type-invalid' && (
          <FormHelperText error>File type must be *.jpeg, *.jpg, *.png, *.gif</FormHelperText>
        )}
      </Box>
      {caption}
      {fileRejections.length > 0 && <ShowRejectionItems />}
    </>
  );
}
