import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
// mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {FormikProvider, Form, useFormik} from 'formik';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addOrg} from 'src/redux/slices/organization';
import {addClientOrg} from 'src/redux/slices/client';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import {UploadMultiFile} from 'src/components/upload';
import NumberFormat from 'src/components/NumberFormat';
import FileEditor from 'src/components/FileEditor';
// constants
import {BD_ROLES, BD_PROFIT_STATUS, ORGANIZATION_STATUS} from 'src/constants/dropdown';
// ----------------------------------------------------------------------
const TYPE = 'board';
const organizationeDocs = ['indemnityDocs', 'doDocs'];

BoardDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default function BoardDialog({handleClose, clientId, open}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [files, setFiles] = useState(organizationeDocs.reduce((res, key) => ({...res, [key]: []}), {}));
  const BDSchema = Yup.object().shape({
    name: Yup.string(),
    industry: Yup.string(),
    indemnityDocs: Yup.array()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      industry: '',
      website: '',
      profitStatus: '',
      role: '',
      compensation: '',
      indemnity: false,
      indemnityReviewed: false,
      expense: '',
      status: 'active',
      value: '',
      note: '',
      indemnityDocs: [],
      doDocs: []
    },
    validationSchema: BDSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: TYPE};
        const res = !!clientId
          ? await dispatch(addClientOrg(payload, clientId, !isAdvisor))
          : await dispatch(addOrg(payload));

        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('New Org has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/org/board/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/org/board/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Org creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  const handleDropMultiFile = useCallback((acceptedFiles, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    }));
  }, []);

  const handleRemoveAll = (key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: []
    }));
  };

  const handleRemove = (file, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: files[key].filter((_file) => _file !== file)
    }));
  };

  const handleUpload = useCallback(
    (uploadedFiles, key) => {
      setFieldValue(key, [...values[key], ...uploadedFiles]);
      setFiles((allFiles) => ({
        ...allFiles,
        [key]: []
      }));
    },
    [setFieldValue, values]
  );

  const handleDeleteFile = (id, key) => {
    setFieldValue(
      key,
      values[key].filter((doc) => doc.id !== id)
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Board</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('name')}
                  fullWidth
                  type="text"
                  label="Organization Name"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  {...getFieldProps('industry')}
                  fullWidth
                  type="text"
                  label="Industry"
                  error={Boolean(touched.industry && errors.industry)}
                  helperText={touched.industry && errors.industry}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Profit Status"
                  {...getFieldProps('profitStatus')}
                  onChange={(e) => setFieldValue('profitStatus', e.target.value)}
                  helperText="Please select profit status."
                >
                  {BD_PROFIT_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  label="Role"
                  {...getFieldProps('role')}
                  onChange={(e) => setFieldValue('role', e.target.value)}
                  helperText="Please select role."
                >
                  {BD_ROLES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <NumberFormat
                  fullWidth
                  label="Compensation"
                  prefix="$"
                  {...getFieldProps('compensation')}
                  onChange={(e) => setFieldValue('compensation', parseFloat(e.target.values.value))}
                />
                <NumberFormat
                  fullWidth
                  label="Expense"
                  prefix="$"
                  {...getFieldProps('expense')}
                  onChange={(e) => setFieldValue('expense', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  select
                  fullWidth
                  label="Organization Status"
                  {...getFieldProps('status')}
                  onChange={(e) => setFieldValue('status', e.target.value)}
                  helperText="Please select status."
                >
                  {ORGANIZATION_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <NumberFormat
                  fullWidth
                  label="Value"
                  prefix="$"
                  placeholder="$0"
                  {...getFieldProps('value')}
                  onChange={(e) => setFieldValue('value', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <Stack>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => setFieldValue('indemnity', e.target.checked)}
                        checked={values.indemnity || false}
                      />
                    }
                    label="Indemnity"
                  />
                </Stack>
                <Stack>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => setFieldValue('indemnityReviewed', e.target.checked)}
                        checked={values.indemnityReviewed || false}
                      />
                    }
                    label="Indemnity Reviewed"
                  />
                </Stack>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('website')}
                  fullWidth
                  type="text"
                  label="Website"
                  error={Boolean(touched.website && errors.website)}
                  helperText={touched.website && errors.website}
                />
              </Stack>
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{p: 1}}>
                    <Typography variant="body1">Indemnity Docs</Typography>
                    <UploadMultiFile
                      showPreview={false}
                      files={files.indemnityDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'indemnityDocs')}
                      onRemove={(arg) => handleRemove(arg, 'indemnityDocs')}
                      onRemoveAll={() => handleRemoveAll('indemnityDocs')}
                      documentType="entity"
                      subType="board"
                      fileType="indemnityDocs"
                      onUpload={(arg) => handleUpload(arg, 'indemnityDocs')}
                    />
                    <FileEditor
                      files={values.indemnityDocs}
                      deleteFileFunc={(id) => handleDeleteFile(id, 'indemnityDocs')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{p: 1}}>
                    <Typography variant="body1">D&O Docs</Typography>
                    <UploadMultiFile
                      showPreview={false}
                      files={files.doDocs}
                      onDrop={(arg) => handleDropMultiFile(arg, 'doDocs')}
                      onRemove={(arg) => handleRemove(arg, 'doDocs')}
                      onRemoveAll={() => handleRemoveAll('doDocs')}
                      documentType="entity"
                      subType="board"
                      fileType="doDocs"
                      onUpload={(arg) => handleUpload(arg, 'doDocs')}
                    />
                    <FileEditor files={values.doDocs} deleteFileFunc={(id) => handleDeleteFile(id, 'doDocs')} />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}
