// routes
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import SvgIconStyle from 'src/components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name, height = '100%', width = '100%') => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{width: width, height: height}} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  proposals: getIcon('ic_kanban'),
  assets: getIcon('ic_asset'),
  insurance: getIcon('ic_insurance'),
  mail: getIcon('ic_mail'),
  chat: getIcon('ic_chat'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  vault: getIcon('ic_authenticator')
};

export const SMALL_ICONS = {
  dashboard: getIcon('ic_dashboard', 20, 20),
  proposals: getIcon('ic_kanban', 20, 20),
  assets: getIcon('ic_asset', 20, 20),
  insurance: getIcon('ic_insurance', 20, 20),
  mail: getIcon('ic_mail', 20, 20),
  chat: getIcon('ic_chat', 20, 20),
  banking: getIcon('ic_banking', 20, 20),
  calendar: getIcon('ic_calendar', 20, 20),
  vault: getIcon('ic_authenticator', 20, 20),
  client: getIcon('ic_user', 20, 20)
};

const sidebarConfig = [
  // DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: 'dashboard',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.app,
        icon: ICONS.dashboard
      }
    ]
  },
  {
    subheader: 'proposals',
    items: [
      {
        title: 'Proposals',
        path: PATH_DASHBOARD.proposals,
        icon: ICONS.proposals
      }
    ]
  },
  // DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: 'contacts',
    items: [
      {
        title: 'Contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.mail,
        children: [
          {title: 'All', path: `${PATH_DASHBOARD.contacts.root}/?view=all`},
          {title: 'Family', path: `${PATH_DASHBOARD.contacts.root}/?view=family`},
          {title: 'Staff', path: `${PATH_DASHBOARD.contacts.root}/?view=staff`},
          {title: 'Insurance', path: `${PATH_DASHBOARD.contacts.root}/?view=insurance`},
          {title: 'Accounting', path: `${PATH_DASHBOARD.contacts.root}/?view=accounting`},
          {title: 'Financial', path: `${PATH_DASHBOARD.contacts.root}/?view=financial`},
          {title: 'Legal', path: `${PATH_DASHBOARD.contacts.root}/?view=legal`},
          {title: 'Real Estate', path: `${PATH_DASHBOARD.contacts.root}/?view=realEstate`},
          {title: 'Institutions', path: `${PATH_DASHBOARD.contacts.root}/?view=institutions`},
          {title: 'Other', path: `${PATH_DASHBOARD.contacts.root}/?view=other`}
        ]
      }
    ]
  },

  // ASSETS
  // ----------------------------------------------------------------------
  {
    subheader: 'asset',
    items: [
      {
        title: 'Assets & Liabilities',
        path: PATH_DASHBOARD.assets.root,
        icon: ICONS.assets,
        children: [
          {title: 'General', path: `${PATH_DASHBOARD.assets.root}/?view=general`},
          {title: 'Real Estate', path: `${PATH_DASHBOARD.assets.root}/?view=realEstate`},
          {title: 'Collections', path: `${PATH_DASHBOARD.assets.root}/?view=collection`},
          {title: 'Automobiles', path: `${PATH_DASHBOARD.assets.root}/?view=automobile`},
          {title: 'Watercraft', path: `${PATH_DASHBOARD.assets.root}/?view=watercraft`},
          {title: 'Aircraft', path: `${PATH_DASHBOARD.assets.root}/?view=aircraft`},
          {title: 'Equine', path: `${PATH_DASHBOARD.assets.root}/?view=equine`},
          {title: 'Divested', path: `${PATH_DASHBOARD.assets.root}/?view=divested`}
        ]
      }
    ]
  },

  // INSURANCE
  // ----------------------------------------------------------------------
  {
    subheader: 'insurance',
    items: [
      {
        title: 'Insurance',
        path: PATH_DASHBOARD.insurance.root,
        icon: ICONS.insurance,
        children: [
          {title: 'General', path: `${PATH_DASHBOARD.insurance.root}/?view=general`},
          {title: 'Real Estate', path: `${PATH_DASHBOARD.insurance.root}/?view=realEstate`},
          {title: 'Collections', path: `${PATH_DASHBOARD.insurance.root}/?view=collection`},
          {title: 'Automobiles', path: `${PATH_DASHBOARD.insurance.root}/?view=automobile`},
          {title: 'Watercraft', path: `${PATH_DASHBOARD.insurance.root}/?view=watercraft`},
          {title: 'Aircraft', path: `${PATH_DASHBOARD.insurance.root}/?view=aircraft`},
          {title: 'Equine', path: `${PATH_DASHBOARD.insurance.root}/?view=equine`},
          {title: 'Umbrella', path: `${PATH_DASHBOARD.insurance.root}/?view=umbrella`},
          {title: 'Misc', path: `${PATH_DASHBOARD.insurance.root}/?view=misc`},
          {title: 'Claims', path: `${PATH_DASHBOARD.insurance.root}/?view=claim`},
          {title: 'Dormant', path: `${PATH_DASHBOARD.insurance.root}/?view=dormant`}
        ]
      }
    ]
  },

  // ORGANIZATIONS
  // ----------------------------------------------------------------------
  {
    subheader: 'organizations',
    items: [
      {
        title: 'Organizations',
        path: PATH_DASHBOARD.org.root,
        icon: ICONS.banking,
        children: [
          {title: 'Boards', path: `${PATH_DASHBOARD.org.root}/?view=board`},
          {title: 'Entities', path: `${PATH_DASHBOARD.org.root}/?view=entity`},
          {title: 'Trusts & Estates', path: `${PATH_DASHBOARD.org.root}/?view=trust`},
          {title: 'Clubs', path: `${PATH_DASHBOARD.org.root}/?view=club`}
        ]
      }
    ]
  },

  // LEDGER
  // ----------------------------------------------------------------------
  {
    subheader: 'ledger',
    items: [
      {
        title: 'Ledger',
        path: PATH_DASHBOARD.ledger,
        icon: ICONS.chat
      }
    ]
  },

  // CALENDAR
  // ----------------------------------------------------------------------
  {
    subheader: 'calendar',
    items: [
      {
        title: 'Calendar',
        path: PATH_DASHBOARD.calendar,
        icon: ICONS.calendar
      }
    ]
  },

  // DOCUMENT VAULT
  // ----------------------------------------------------------------------
  {
    subheader: 'document-vault',
    items: [
      {
        title: 'File Vault',
        path: PATH_DASHBOARD.fileVault,
        icon: ICONS.vault
      }
    ]
  }
];

export default sidebarConfig;