import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {capitalCase} from 'change-case';
// mui
import {Card, CircularProgress, Stack, Typography} from '@mui/material';
// api
import {getAssetDetail} from 'src/api';
// hooks
import useAuth from 'src/hooks/useAuth';
// constants
import {getIcon} from 'src/utils/items';
// ----------------------------------------------------------------------

RiskDetails.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  userId: PropTypes.string,
  showType: PropTypes.bool
};

RiskDetails.defaultProps = {
  userId: ''
};

export default function RiskDetails({type, id, userId, showType = true}) {
  const navigate = useNavigate();
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const [riskDetail, setRiskDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const icon = getIcon('asset', type);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const data = await getAssetDetail(type, id, userId, !isAdvisor);
        if (isMounted) {
          if (data && data.id) {
            setRiskDetail(`${showType ? capitalCase(type) + ': ' : ''}${data.nickName}`);
          }
        }
      } catch (err) {
        console.log('Risk-card: ', err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (!!id && !!type) fetchData();
    return () => {
      isMounted = false;
    };
  }, [id, userId, type, showType, isAdvisor]);

  const handleClick = () => {
    navigate(!userId ? `/dashboard/asset/${type}/${id}` : `/dashboard/asset/${type}/${id}/${userId}`);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return !riskDetail ? null : (
    <Card sx={{cursor: 'pointer', px: 2, py: 1}} onClick={handleClick}>
      <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
        {icon}
        <Typography>{riskDetail}</Typography>
      </Stack>
    </Card>
  );
}
