import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import {FormikProvider, Form, useFormik} from 'formik';
// mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// hook
import useAuth from 'src/hooks/useAuth';
// redux
import {useDispatch} from 'src/redux/store';
import {addOrg} from 'src/redux/slices/organization';
import {addClientOrg} from 'src/redux/slices/client';
// utils
import {formatPhoneNumber, getCleanPhoneNumber} from 'src/utils/stringFormat';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import {UploadMultiFile} from 'src/components/upload';
import NumberFormat from 'src/components/NumberFormat';
import FileEditor from 'src/components/FileEditor';
import {AddressFields, ContactOrgSelect} from 'src/components/_dashboard/common';
// constants
import {ORGANIZATION_STATUS, TR_TYPE, TR_SUB_TYPE} from 'src/constants/dropdown';
// ----------------------------------------------------------------------
const organizationeDocs = ['entityDocs'];

TrustDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default function TrustDialog({handleClose, clientId, open}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [files, setFiles] = useState(organizationeDocs.reduce((res, key) => ({...res, [key]: []}), {}));
  const TDSchema = Yup.object().shape({
    name: Yup.string(),
    entityType: Yup.string(),
    entityDocs: Yup.array()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      entityType: '',
      entityTrustType: '',
      type: '',
      addressId: '',
      primaryPhone: '',
      trustee: [],
      beneficiaries: [],
      status: 'active',
      estatePlanningValue: '', // Estate Planning Entity Value
      note: '',
      entityDocs: [] // Entity Planning Docs
    },
    validationSchema: TDSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const payload = {...values, type: 'trust-estate'};
        const res = !!clientId
          ? await dispatch(addClientOrg(payload, clientId, !isAdvisor))
          : await dispatch(addOrg(payload));
        setSubmitting(false);
        if (res && res?.data) {
          enqueueSnackbar('New Org has been created.', {variant: 'success'});
          const id = res?.data.id || res?.data._id;
          const route = !!clientId
            ? `${PATH_DASHBOARD.root}/org/trust-estate/${id}/${clientId}`
            : `${PATH_DASHBOARD.root}/org/trust-estate/${id}`;

          navigate(route);
        } else {
          if (res?.statusCode !== 200 && user.role === 'Advisor') {
            enqueueSnackbar(res?.message || 'You are not permitted to do this action', {variant: 'error'});
          } else {
            enqueueSnackbar('Org creation error.', {variant: 'error'});
          }
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const {values, touched, errors, getFieldProps, setFieldValue, handleSubmit, isSubmitting} = formik;

  const handleDropMultiFile = useCallback((acceptedFiles, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    }));
  }, []);

  const handleRemoveAll = (key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: []
    }));
  };

  const handleRemove = (file, key) => {
    setFiles((allFiles) => ({
      ...allFiles,
      [key]: files[key].filter((_file) => _file !== file)
    }));
  };

  const handleUpload = useCallback(
    (uploadedFiles, key) => {
      setFieldValue(key, [...values[key], ...uploadedFiles]);
      setFiles((allFiles) => ({
        ...allFiles,
        [key]: []
      }));
    },
    [setFieldValue, values]
  );

  const handleDeleteFile = (id, key) => {
    setFieldValue(
      key,
      values[key].filter((doc) => doc.id !== id)
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Add New Trust (OR Estate)</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  {...getFieldProps('name')}
                  fullWidth
                  type="text"
                  label="Organization Name"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  select
                  fullWidth
                  label="Type"
                  {...getFieldProps('entityType')}
                  onChange={(e) => setFieldValue('entityType', e.target.value)}
                >
                  {TR_TYPE.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                {values.entityType === 'trust' && (
                  <TextField
                    select
                    fullWidth
                    label="Trust Type"
                    {...getFieldProps('entityTrustType')}
                    onChange={(e) => setFieldValue('entityTrustType', e.target.value)}
                  >
                    {TR_SUB_TYPE.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="Primary Phone"
                  value={formatPhoneNumber(values.primaryPhone)}
                  onChange={(e) => setFieldValue('primaryPhone', getCleanPhoneNumber(e.target.value))}
                  error={Boolean(touched.primaryPhone && errors.primaryPhone)}
                  helperText={touched.primaryPhone && errors.primaryPhone}
                />
              </Stack>
              <AddressFields
                isRealEstate={false}
                addId={values.addressId}
                handleChange={(id) => {
                  setFieldValue('addressId', id);
                }}
                inputProps={{
                  error: Boolean(touched.addressId && errors.addressId),
                  helperText: touched.addressId && errors.addressId
                }}
              />
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <ContactOrgSelect
                  multiple
                  value={values.trustee}
                  label="Trustee"
                  handleChange={(val) =>
                    setFieldValue(
                      'trustee',
                      val.map((item) => item.id)
                    )
                  }
                />
                <TextField
                  select
                  fullWidth
                  label="Organization Status"
                  {...getFieldProps('status')}
                  onChange={(e) => setFieldValue('status', e.target.value)}
                >
                  {ORGANIZATION_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <ContactOrgSelect
                  multiple
                  value={values.beneficiaries}
                  label="Beneficiaries"
                  handleChange={(val) =>
                    setFieldValue(
                      'beneficiaries',
                      val.map((item) => item.id)
                    )
                  }
                />
                <NumberFormat
                  fullWidth
                  label="Estate Planning Value"
                  prefix="$"
                  placeholder="$0"
                  {...getFieldProps('estatePlanningValue')}
                  onChange={(e) => setFieldValue('estatePlanningValue', parseFloat(e.target.values.value))}
                />
              </Stack>
              <Stack spacing={2}>
                <TextField fullWidth multiline minRows={3} maxRows={5} label="Note" {...getFieldProps('note')} />
              </Stack>
              <Box>
                <Typography variant="body1">Entity Planning Docs</Typography>
                <UploadMultiFile
                  showPreview={false}
                  files={files.entityDocs}
                  onDrop={(arg) => handleDropMultiFile(arg, 'entityDocs')}
                  onRemove={(arg) => handleRemove(arg, 'entityDocs')}
                  onRemoveAll={() => handleRemoveAll('entityDocs')}
                  documentType="entity"
                  subType="trust"
                  fileType="entityDocs"
                  onUpload={(arg) => handleUpload(arg, 'entityDocs')}
                />
                <FileEditor files={values.entityDocs} deleteFileFunc={(id) => handleDeleteFile(id, 'entityDocs')} />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}
